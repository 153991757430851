/*
 * Helpers and constants used globally
 */

import { useRef, useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import toast from 'react-hot-toast';
import crypto from "crypto";

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return (() => {
      isMounted.current = false;
    });
  }, []);

  return isMounted;
}

export let mobileForTest = false;

export const isAndroid = () => navigator.userAgent.indexOf("android") > -1;
export const isSamsung = () => navigator.userAgent.indexOf("SamsungBrowser") > -1;
export const isAppleiOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
};
export const hasTinyScreen = () => window.matchMedia('(max-width: 600px)').matches;
export const isMobile = () => isAndroid() || isSamsung() || isAppleiOS() || hasTinyScreen() || mobileForTest;

export function getRandomValue(): number{
  return crypto.randomBytes(4).readUInt32LE(0) / 0x100000000;
}

export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(getRandomValue() * (max - min) + min);
}

export function toHash(arr: any[], key: string, val: string) {
  return arr.length > 0 ? arr.reduce(function (map: {}, obj: { key: any, val: any }): any {
    map[obj.key] = obj.val;
    return map;
  }) : {};
}

// Takes a hash, and converts its key's to a string array
export function toArray(hash:{}) {
  let arr = [];
  for (const key in hash) {
    arr.push(key);
  }
  return arr;
}

export function usCurrencyFormat(num) {
  if (typeof (num) === 'number') {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  } else {
    return '';
  }
}

export function usCurrencyFormatShortenedToMillions(num) {
  if (num >= 1000000) return shortenedCurrencyFormatMillions(num);
  if (num >= 1000) return shortenedCurrencyFormat(num);
  return usCurrencyFormat(num);

  function shortenedCurrencyFormatMillions(num) {
    let displayValue = Math.round((num / 10000)) / 100;
    return `${usCurrencyFormat(displayValue)}M`;
  }

  function shortenedCurrencyFormat(num) {
    let displayValue = Math.round((num / 10)) / 100;
    return `${usCurrencyFormat(displayValue)}K`;
  }
}

export function percentageSuffix(num) {
  if (typeof (num) === 'number') {
    return `${num}%`;
  } else {
    return '';
  }
}

export function takeFirstIfExistWithZeroAccepted(first, second) {
  return first || first === 0 ? first : second;
}

export function saveUserDataToSessionStorage(userData){
  try{
    sessionStorage.setItem("LCM_accessToken", userData.signInUserSession.accessToken.jwtToken);
    sessionStorage.setItem("LCM_expValue", userData.signInUserSession.accessToken.payload.exp);
    sessionStorage.setItem("LCM_userData", JSON.stringify(userData.attributes));
    sessionStorage.setItem("LCM_userId", userData.attributes.sub);
    const userRoles = (userData.signInUserSession.accessToken.payload) ? userData.signInUserSession.accessToken.payload["cognito:groups"] : [];
    sessionStorage.setItem("LCM_userRoles", JSON.stringify(userRoles));
    return true
  } catch(e){
    return false
  }
}

export function getUserDataFromSessionStorage(){
  let userDataData = {
    id: '',
    name: '',
    firstName: '',
    lastName: '',
    email: '',
  };

  try{
    const userDataParsed = JSON.parse(sessionStorage.getItem("LCM_userData"));
    userDataData.id = userDataParsed.sub;
    userDataData.name = `${userDataParsed.given_name} ${userDataParsed.family_name}`;
    userDataData.firstName = userDataParsed.given_name;
    userDataData.lastName = userDataParsed.family_name;
    userDataData.email = userDataParsed.email;
  }catch(e){
    console.error("User data saved is not valid!");
  }

  return userDataData;
}

export function getAccessTokenSessionStorage(){
  return sessionStorage.getItem("LCM_accessToken");
}

export function getUserIdFromSessionStorage(){
  return sessionStorage.getItem("LCM_userId");
}

export function clearUserDataToSessionStorage(){
  sessionStorage.removeItem('LCM_accessToken');
  sessionStorage.removeItem('LCM_expValue');
  sessionStorage.removeItem('LCM_userId');
  sessionStorage.removeItem('LCM_userRoles');
  sessionStorage.removeItem('LCM_userIsAdmin');
}

export function validateUserRole(role:string, rolesData?:string): boolean{
  const userRolesData = rolesData || sessionStorage.getItem("LCM_userRoles");
  if (!userRolesData) return false;
  let userRoles;

  try{
    userRoles = JSON.parse(userRolesData);
  }catch(e){
    console.error("User roles saved are not valid!");
  }

  if (!userRoles || !userRoles.length) return false;

  // Check admin Role
  let userIsRole = false;
  userRoles.forEach((currentRole: string) => {
    if (currentRole.toLowerCase() === `role:${role.toLowerCase()}`){
      userIsRole = true;
    }
  });

  return userIsRole;
}

export function validateUserRoles(roles: string[], rolesData?: string){
  let validRoles = true;
  roles.forEach((role) => {
    if (!validateUserRole(role, rolesData)){
      validRoles = false;
    };
  });

  return validRoles;
}

export function validateAnyUserRoles(roles: string[], rolesData?: string){
  let validRoles = false;
  roles.forEach((role) => {
    if (validateUserRole(role, rolesData)){
      validRoles = true;
    };
  });

  return validRoles;
}

export function refreshToken(callBack?, args?):Boolean {
  const expValue = sessionStorage.getItem("LCM_expValue");
  if ((Date.now()/1000 > parseInt(expValue))) {
    Auth.currentSession()
    .then(data => {
      // @ts-ignore
      sessionStorage.setItem("LCM_accessToken", data.accessToken.jwtToken);
      // @ts-ignore
      sessionStorage.setItem("LCM_expValue", data.accessToken.payload.exp);
      callBack&&callBack(...args);

    })
    .catch(err => console.log(err));
    return true;
  }
  else {
    return false;
  }
}

export function logScale(int, logScaleVal):number {
  return Math.log(int) / Math.log(logScaleVal);
}

export const companyIdRegExp = /^[a-zA-Z0-9+._-\s]{3,}$/;
export const mentionsRegExp = /[{[]+['"]*id['"]*:[ '"]*[0-9-a-z]+['"]*,[ "]*name["]*:[ '"]*[@#$][a-zA-Z0-9-.]+[ '"]*[}\]]+/g;
export const emailRegExp = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
export const nameRegExp = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/;
export const nameNoSpacesRegExp = /^[a-zA-Z0-9._@#$!%*?&=-]{3,}$/;
export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&-_+=])[A-Za-z\d@#$!%*?&-_+=]{8,}$/;
export const phoneNumberRegExp = /^[0-9+._-\s]{6,}$/;
export const urlRegExp = /^http(s?):\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-.?,'/\\+&amp;%$#_]*)?$/;
export const zipCodeRegExp = /^[a-zA-Z0-9-\s]{3,}$/;

export const validateName = (text) => {
  if (!text) return false;
  return text.match(nameRegExp);
}

export const validateNameNoSpaces = (text) => {
  if (!text) return false;
  return text.match(nameNoSpacesRegExp);
}

export const validateEmail = (text) => {
  if (!text) return false;
  return text.match(emailRegExp);
}

export const validatePassword = (text) => {
  if (!text) return false;
  return text.match(passwordRegExp);
}

export const validatePhoneNumber = (text) => {
  if (!text) return false;
  return text.match(phoneNumberRegExp);
}

export const validateStartDate = (startDate) => {
  if (!startDate) return false;
  return true;
}

export const validateURL = (text) => {
  if (!text) return false;
  return text.match(urlRegExp);
}

export const validatePostalCode = (text) => {
  if (!text) return false;
  return text.match(zipCodeRegExp);
}

export const validateTextLength = (text, minLength) => {
  if (!text) return false;
  return text.length >= minLength;
}

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const showToastMessage = (message: string, type?: string, options?) => {
  if (!message) return;

  let toastId: string;
  const toastOptions = {
    position: options?.position || 'bottom-center',
    style: options?.position ||  {
      background: '#363636',
      color: '#fff',
      minWidth: '30%',
    },
    id: options?.id || '',
  };

  if (options?.duration){
    toastOptions['duration'] = options?.duration;
  }

  switch(type){
    case 'success':
      toastId = toast.success(message, toastOptions);
      break;

    case 'error':
      toastId = toast.error(message, toastOptions);
      break;

    case 'loading':
      toastId = toast.loading(message, toastOptions);
      break;

    case 'alert':
      const customOptions = {
        duration: 5000,
        position: toastOptions.position,
        style: toastOptions.style,
        icon: '⚠',
        id: options?.id || null,
      };
      toast(message, customOptions);
      break;

    default:
      toastId = toast(message, toastOptions);
      break;
  }

  return toastId;
}

export const hideToastMessage = (toastId?: string) => {
  toastId ? toast.dismiss(toastId) : toast.dismiss();
}

export const beautifyConstrain = (originalText) => {
  if (!originalText) return originalText;

  let text = originalText;
  text = text.replaceAll('_', ' ');
  return text;
};

export function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export const countriesList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Côte d\'Ivoire',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo (Congo-Brazzaville)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czechia (Czech Republic)',
  'Democratic Republic of the Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini (fmr. "Swaziland")',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See,003',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives,802',
  'Mali',
  'Malta,380',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco,337',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (formerly Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine State',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore,358',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const documentFileTypes:string[] = [
  'text/plain',
  'text/csv',
  'application/rtf',
  'application/vnd.ms-excel',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];