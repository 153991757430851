import React, { useEffect, useState } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, 
  FormControl, OutlinedInput, InputLabel, Select, MenuItem, ListItemText, Button, InputAdornment,
} from '@mui/material';
import Layout from '../../components/Layout';
import UsersAddPage from './UsersAddPage';
import UsersViewPage from './UsersViewPage';
import UsersEditPage from './UsersEditPage';
import { getUsers, deleteUser, resendInvitation } from '../../api/LCMApi';
import CustomModal from '../../components/custom-modal/CustomModal';
import PromptModal from '../../components/custom-modal/PromptModal';
import { useIsMounted, showToastMessage, hideToastMessage } from '../../helpers/global-helpers';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import EmailOutlined from '@mui/icons-material/Email';
import './UsersPage.scss';

const UsersPage = () => {
  const isMounted = useIsMounted();

  const [filterName, setFilterName] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  const [loadingData, setLoadingData] = useState(true);
  const [errorInData, setErrorInData] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [usersOrderBy, setUsersOrderBy] = useState({ field: 'organization', mode: 'asc' });

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [viewUserModalOpen, setViewUserModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [resendInvitationModalOpen, setResendInvitationModalOpen] = useState(false);

  const defaultUserData = {
    id: '',
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    status: '',
  };
  const [userData, setUserData] = useState(defaultUserData);

  async function getUsersData() {
    setLoadingData(true);
    const usersData = await getUsers();
    if (!isMounted.current) return;

    if (!usersData.status){
      setErrorInData(true);
      setLoadingData(false);
      return;
    };

    setUsers(usersData.data);
    filterUsers(usersData.data);
    setLoadingData(false);
  }

  const validateEmailAsUser = (email) => {
    let emailExists = false;
    for (let i = 0; i < users.length && !emailExists; i++){
      if (users[i].email === email) emailExists = true;
    }

    return emailExists;
  }

  const filterUsers = (userListToUse?: []) => {
    let userData = [];
    const usersList = (userListToUse) ? userListToUse : users;

    if (!filterName && !filterStatus){
      userData = applyOrderBy(usersList.slice());
      setFilteredUsers(userData);
      return;
    }

    usersList.forEach((user) => {
      let validOption = true;
      if (filterName && user.name.toLowerCase().indexOf(filterName.toLocaleLowerCase()) <= -1 && user.email.toLowerCase().indexOf(filterName.toLocaleLowerCase()) <= -1){
        validOption = false;
      }

      if (validOption && filterStatus && user.status.toLowerCase() !== filterStatus.toLowerCase()){
        validOption = false;
      }

      if (validOption) userData.push(user);
    });

    userData = applyOrderBy(userData);
    setFilteredUsers(userData); 
  }

  const applyOrderBy = (usersData) => {
    usersData.sort((a, b) => {
      if (usersOrderBy.field === 'name'){
        if (usersOrderBy.mode === 'asc'){
          return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1;
        }else{
          return (a.name.toLowerCase() >= b.name.toLowerCase()) ? -1 : 1;
        }
      }

      if (usersOrderBy.field === 'email'){
        if (usersOrderBy.mode === 'asc'){
          return (a.email.toLowerCase() < b.email.toLowerCase()) ? -1 : 1;
        }else{
          return (a.email.toLowerCase() >= b.email.toLowerCase()) ? -1 : 1;
        }
      }

      if (usersOrderBy.field === 'phone'){
        if (usersOrderBy.mode === 'asc'){
          if (!a.phoneNumber) return -1;
          if (!b.phoneNumber) return 1;
          return (a.phoneNumber.toLowerCase() < b.phoneNumber.toLowerCase()) ? -1 : 1;
        }else{
          if (!a.phoneNumber) return 1;
          if (!b.phoneNumber) return -1;
          return (a.phoneNumber.toLowerCase() >= b.phoneNumber.toLowerCase()) ? -1 : 1;
        }
      }

      if (usersOrderBy.field === 'status'){
        if (usersOrderBy.mode === 'asc'){
          return (a.status.toLowerCase() < b.status.toLowerCase()) ? -1 : 1;
        }else{
          return (a.status.toLowerCase() >= b.status.toLowerCase()) ? -1 : 1;
        }
      }

      return 1;
    });
    return usersData;
  };

  const setUsersOrder = (orderBy) => {
    switch(orderBy){
      case 'name':
      case 'email':
      case 'phone':
      case 'status':
        setUsersOrderBy({
          field: orderBy, 
          mode: (usersOrderBy.field === orderBy && usersOrderBy.mode === 'asc') ? 'desc' : 'asc'
        });
        return;
    }
  };

  const getPillColorFromStatus = (status) => {
    if (!status) return "red";

    switch(status.toUpperCase()){
      case "INVITED":
        return "yellow";

      case "ACTIVE":
        return "green";

      case "BLOCKED":
        return "red";
    }

    return "red";
  }

  const resendInvitationToUser = (user) => {
    setUserData(user);
    setResendInvitationModalOpen(true);
  }

  async function confirmResendInvitationToUser(){
    setResendInvitationModalOpen(false);

    showToastMessage('Sending invitation to user...', 'loading', { id: 'users' });
    const result = await resendInvitation(userData.id, userData.email);
    if (!isMounted.current) return;

    if (!result.status){
      showToastMessage('Invitation not sent to user...', 'error', { id: 'users' });
      return;
    }
 
    showToastMessage(`Invitation sent to ${userData.email}!`, 'success', { id: 'users' });
  }

  const viewUser = (user) => {
    setUserData(user);
    setViewUserModalOpen(true);
  }

  const editUser = (user) => {
    setUserData(user);
    setEditUserModalOpen(true);
  }

  const deleteUserConfirmation = (user) => {
    setUserData(user);
    setDeleteUserModalOpen(true);
  }

  async function confirmDeletingUser(){
    setDeleteUserModalOpen(false);

    showToastMessage('Deleting user...', 'loading', { id: 'users' });
    const result = await deleteUser(userData.id);
    if (!isMounted.current) return;

    if (!result.status){
      showToastMessage('Error deleting user...', 'error', { id: 'users' });
      return;
    }

    showToastMessage('user deleted!', 'success', { id: 'users' });
    getUsersData();
  }

  useEffect(() => {
    if (!isMounted.current) return;

    getUsersData();
  
    return(() => {
      hideToastMessage();
    })
  }, []);

  useEffect(() => {
    if (!isMounted.current) return;
    if (users.length <= 0) return;

    filterUsers();
  }, [filterName, filterStatus, usersOrderBy.field, usersOrderBy.mode]);

  return (
    <Layout>
      <CustomModal
        id="add-users-modal"
        title="Add new user"
        shiftedRight={true}
        open={addUserModalOpen}
        handleClose={() => setAddUserModalOpen(false)}
      >
        <UsersAddPage
          userData={userData}
          validateEmail={validateEmailAsUser}
          refreshUserList={() => getUsersData()}
          onClose={() => setAddUserModalOpen(false)}
        />
      </CustomModal>

      <CustomModal
        id="view-users-modal"
        title="User details"
        shiftedRight={true}
        open={viewUserModalOpen}
        handleClose={() => setViewUserModalOpen(false)}
      >
        <UsersViewPage
          userData={userData}
          onClose={() => setViewUserModalOpen(false)}
        />
      </CustomModal>

      <CustomModal
        id="edit-users-modal"
        title="Edit user"
        shiftedRight={true}
        open={editUserModalOpen}
        handleClose={() => setEditUserModalOpen(false)}
      >
        <UsersEditPage
          userData={userData}
          validateEmail={validateEmailAsUser}
          refreshUserList={() => getUsersData()}
          onClose={() => setEditUserModalOpen(false)}
        />
      </CustomModal>

      <PromptModal
        id="resend-invitation-modal"
        title="Resend invitation?"
        text={`Do you confirm resend invitation to '${userData.email}'?`}
        shiftedRight={true}
        open={resendInvitationModalOpen}
        handleConfirm={() => confirmResendInvitationToUser()}
        handleClose={() => setResendInvitationModalOpen(false)}
      />

      <PromptModal
        id="delete-users-modal"
        title="Delete user?"
        text={`Do you confirm deleting user '${userData.name}'?`}
        shiftedRight={true}
        open={deleteUserModalOpen}
        handleConfirm={() => confirmDeletingUser()}
        handleClose={() => setDeleteUserModalOpen(false)}
      />

      <div className="main-section">
        <div className="main-table">
          <div className="table-title no-margin">
            <div className="title">Users</div>
            <div className="actions">
              <Button
                id="button-new-organization"
                variant="outlined"
                className="button-outline"
                onClick={() => { setUserData(defaultUserData); setAddUserModalOpen(true); }}
              >
                NEW USER FORM
              </Button>
            </div>
          </div>

          {!loadingData && users.length > 0 &&
            <div className="table-filter">
              <div className="table-filter-column" style={{ width: '74%', paddingRight: '1%' }}>
                <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                  <InputLabel htmlFor="filter-name" style={{ fontSize: '0.8em' }}>Filter by name/email</InputLabel>
                  <OutlinedInput
                    id="filter-name"
                    value={filterName}
                    label="Filter by name/email"
                    onChange={e => setFilterName(e.target.value)}
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    style={{ fontSize: '0.8em' }}
                    endAdornment={
                      filterName &&
                      <InputAdornment position="end">
                          {filterName && 
                            <Tooltip title="Clear">
                              <IconButton
                                aria-label="clear"
                                onClick={() => setFilterName('')}
                                onMouseDown={(e) => e.preventDefault()}
                                edge="end"
                              >
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          }
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="table-filter-column" style={{ width: '25%' }}>
                <FormControl sx={{ my: 1, width: '100%' }} variant="outlined" fullWidth>
                  <InputLabel htmlFor="filter-status" style={{ fontSize: '0.8em' }}>Filter by status</InputLabel>
                  <Select
                    id="filter-status"
                    value={filterStatus}
                    label="Filter by status"
                    style={{ fontSize: '0.8em' }}
                    onChange={e => setFilterStatus(e.target.value)}
                  >
                    <MenuItem value="">No filter</MenuItem>
                    <MenuItem value="INVITED">Invited</MenuItem>
                    <MenuItem value="ACTIVE">Active</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          }

          <div className="table-content">
            {loadingData && 
              <div className="table-loading">
                Loading users data...
              </div>
            }

            {!loadingData && 
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="table-heading">
                      <TableCell className="fs-11 show-pointer no-select" onClick={() => setUsersOrder('name')}>NAME</TableCell>
                      <TableCell className="fs-11 show-pointer no-select" onClick={() => setUsersOrder('email')}>EMAIL</TableCell>
                      <TableCell className="fs-11 show-pointer no-select" onClick={() => setUsersOrder('phone')}>PHONE #</TableCell>
                      <TableCell className="fs-11 show-pointer no-select" onClick={() => setUsersOrder('status')}>STATUS</TableCell>
                      <TableCell className="fs-11 no-select">ACTIONS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {errorInData && 
                      <TableRow sx={{ border: 0 }}>
                        <TableCell colSpan={5} className="table-loading-no-flex">Error loading users! Please try again later.</TableCell>
                      </TableRow>
                    }

                    {users.length <= 0 && 
                      <TableRow sx={{ border: 0 }}>
                        <TableCell colSpan={5} className="table-loading-no-flex">No users added yet...</TableCell>
                      </TableRow>
                    }

                    {filteredUsers.map((user) => (
                      <TableRow
                        key={user.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell className="table-cell-one">{user.name}</TableCell>
                        <TableCell className="table-cell">{user.email}</TableCell>
                        <TableCell className="table-cell" align="center">{user.phoneNumber}</TableCell>
                        <TableCell className="table-cell" align="center">
                          <span className={`pill pill-${getPillColorFromStatus(user.status)}`}>{user.status}</span>
                        </TableCell>
                        <TableCell className="table-actions align-center">
                          {user.status === 'INVITED' &&
                            <Tooltip title="Resend Invitation">
                              <IconButton onClick={() => resendInvitationToUser(user)}>
                                <EmailOutlined />
                              </IconButton>
                            </Tooltip>
                          }
                          {user.status !== 'INVITED' &&  
                            <Tooltip title="View user">
                              <IconButton onClick={() => viewUser(user)}>
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                          }
                          {user.status !== 'INVITED' && false && 
                            <Tooltip title="Edit user">
                              <IconButton onClick={() => editUser(user)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          }
                          <Tooltip title="Delete user">
                            <IconButton onClick={() => deleteUserConfirmation(user)}>
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default UsersPage;
