// Context initial Data
export const AppDefaultData = {
  isAuthed: false,
  currentURI: '',
  userAuthData: {
    challengeParam: {
      userAttributes: {
        email: '',
      }
    },
    username: '',
  },
  userData: {},
};