import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Auth from '@aws-amplify/auth';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Button } from '@mui/material';
import { useIsMounted, emailRegExp, passwordRegExp } from '../helpers/global-helpers';

/**************************************
** Component styles
***************************************/
import { Visibility, VisibilityOff } from '@mui/icons-material';
import './ForgotPasswordPage.scss';

let emailQS = '';

export const ForgotPasswordPage = (props) => {
  const isMounted = useIsMounted();

  const [email, setEmail] = useState('');
  const [emailCode, setEmailCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passVisibility, setPassVisibility] = useState(false);
  const [passVisibilityConfirmation, setPassVisibilityConfirmation] = useState(false);
  const [formInProgress, setFormInProgress] = useState(false);

  const history = useHistory();
  const [formError, setFormError] = useState('');
  const [emailSentStep1, setEmailSentStep1] = useState(false);
  const [verificationMode, setVerificationMode] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const isDisabled: boolean = !email ? true : false;
  const isDisabledVerification: boolean = (!verificationCode || !password || !passwordConfirmation);

  function handleShowPassword() {
    setPassVisibility(!passVisibility);
  }

  function handleMouseDownPassword(event){
    event.preventDefault();
  }

  function handleShowPasswordConfirmation() {
    setPassVisibilityConfirmation(!passVisibilityConfirmation);
  }

  function showFormStep1(){
    setEmailSentStep1(false);
    setVerificationMode(false);
    setFormError('');
    if (emailQS){
      if (emailQS === email) setEmail('');
      redirectTo('/forgotPassword');
      return;
    }
  }

  function showFormStep2(){
    setFormError('');
    setEmailSentStep1(false);
    setVerificationMode(true);
  }

  function redirectTo(page: string){
    history.push(page);
  }

  function handleKeyUpEnter(e) {
    if (!verificationMode && (e.key === 'Enter' || e.keycode === 13)) {
      sendEmailForm();
    }
  }

  function setEmailData(data: string){
    if (!verificationMode){
      setEmail(data);
    }else{
      setEmailCode(data);
    }
  }

  async function sendEmailForm() {
    // Validate data
    const validEmail = email.match(emailRegExp);

    if (!validEmail){
      setFormError('Invalid Email!');
      return ;
    }

    // Access BE
    setFormInProgress(true);
    setFormError('Sending data...');

    try{
      const authResponse = await Auth.forgotPassword(email);
      if (!isMounted.current) return;

      console.log(authResponse);

      if (authResponse && authResponse.CodeDeliveryDetails){
        const emailUser = email;
        setEmailCode(emailUser);
        setEmail('');
        setEmailSentStep1(true);
        showFormStep2();
        //setFormError(`Verification code sent to ${authResponse.CodeDeliveryDetails.Destination}!`);
        setFormError(`Verification code sent to email! (if account exists)`);
        setFormInProgress(false);
        return;
      }

      setFormError('Code verification could not be sent!. Please try again.');
    }catch(e){
      const emailUser = email;
      setEmailCode(emailUser);
      setEmail('');
      setEmailSentStep1(true);
      showFormStep2();
      setFormError(`Verification code sent to email! (if account exists)`);
      setFormInProgress(false);
      return;

      /*if (!e || !e.code){
        setFormError('Code verification could not be sent!. Please try again.');
        return;
      }

      switch(e.code){
        case 'UserNotFoundException':
          setFormError('The email entered is not a valid one.');
          return;

        case 'NotAuthorizedException':
          setFormError('You are not authorized to perform this task.');
          break;

        case 'TooManyRequestsException':
          setFormError('Too many requests to change the password. Please try again in a few minutes.');
          break;

        case 'CodeDeliveryFailureException':
        default:
          setFormError('Code verification could not be sent!. Please try again.');
          return;
      }*/
    }
  }

  async function confirmVerificationCode(){
    // Validate data
    const validEmail = emailCode.match(emailRegExp);
    const validCode = verificationCode.match(/^[0-9]{6}$/);

    if (!validEmail){
      setFormError('Invalid Email!');
      return ;
    }

    if (!validCode){
      setFormError('Invalid Code!');
      return ;
    }

    const validPass = password.match(passwordRegExp);
    const validPassConfirmation = password === passwordConfirmation;

    if (password.length < 8){
      setFormError('Password should contain at least eight characters!');
      return ;
    }

    if (!validPass){
      setFormError('Password should contain at least one uppercase letter, one number and one special symbol!');
      return ;
    }

    if (!validPassConfirmation){
      setFormError('Passwords don\'t match!');
      return ;
    }

    // Access BE
    setFormInProgress(true);
    setFormError('Sending data...');
    await Auth.forgotPasswordSubmit(emailCode, verificationCode, password)
    .then(() => {
      setEmailCode('');
      setVerificationCode('');
      setPassword('');
      setPasswordConfirmation('');
      setPasswordChanged(true);
      setFormError('Password changed succesfully!');
      setFormInProgress(false);
      return;
    })
    .catch((e) => {
      if (!e || !e.code){
        setFormError('Password has not been changed! Please try again.');
        setFormInProgress(false);
        return;
      }

      // Check Exceptions
      switch(e.code){
        case 'ExpiredCodeException':
        case 'InvalidPasswordException':
          setFormError(e.message);
          break;

        case 'NotAuthorizedException':
          setFormError('You are not authorized to perform this task.');
          break;

        case 'TooManyFailedAttemptsException':
          setFormError('Too many failed attempts. Please try again in a few minutes.');
          break;

        case 'UserNotFoundException':
          setFormError('The verification failed, please check your entered data!');
          //setFormError('The email entered is not a valid one.');
          break;
            
        case 'CodeMismatchException':
        default:
          setFormError('The verification failed, please check your entered data!');
          //setFormError('Çode not validated. Please try again.');
          break;
      }

      setFormInProgress(false);
    });
  }

  useEffect(() => {
    if (!isMounted.current) return;

    const emailFromQS = props?.email || props?.match?.params?.id;
    // Verify access
    let validEmailParam = emailFromQS && emailFromQS.match(emailRegExp);
    if (emailFromQS && (emailFromQS === 'verifyCode' || validEmailParam)){
      if (validEmailParam){
        emailQS = emailFromQS;
        setEmailCode(emailQS);
      }
      setVerificationMode(true);
    }
  }, []);

  return (
    <div id="forgot-password" className="forgot-password">
      <div className="header">
        <a href="/"><img
          id="header-logo"
          src="./images/logos/alchemai-edge_desktop-logo.png"
          className="logo"
          alt="Alchemai EDGE"
        /></a>
      </div>
      <div className="content">
        <div className="banner">
          <div className="banner-internal">
            <div className="logo">
              <img 
                id="logo-right"
                src="./images/logos/alchemai-logo-white.png"
                alt="Alchemai Logo"
              />
            </div>
            <div className="title">Already a member?</div>
            <div className="subtitle">Please fill in this form to sign in</div>

            <div className="action">
              <Button
                id="sign-in-button"
                variant="outlined"
                className="button-outline-login spacer"
                onClick={() => redirectTo('/login')}
              >
                SIGN IN
              </Button>
            </div>
          </div>
        </div>
        <div className="form">
          <div id="forgot-password-form" className="forgot-password-form">
            <div className="title">Forgot Password</div>

            <div className="subtitle">
              { verificationMode && <>Enter the verification code received below and the new password.</>}
              { !verificationMode && <>Enter your email below.<br/>You will receive a verification code to change your password.</>}
            </div>

            <div className="inputs-fields">
              <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="email">Email Adress</InputLabel>
                <OutlinedInput
                  id="forgot-password-email-input"
                  label="Email Address"
                  value={verificationMode ? emailCode : email}
                  onChange={e => setEmailData(e.target.value)}
                  onKeyUp={handleKeyUpEnter}
                  disabled={formInProgress}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                />
              </FormControl>

              {verificationMode &&
                <>
                  <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="email">Verification Code</InputLabel>
                    <OutlinedInput
                      id="forgot-password-code-input"
                      label="Verification Code"
                      value={verificationCode}
                      onChange={e => setVerificationCode(e.target.value)}
                      disabled={formInProgress}
                      fullWidth
                      inputProps={{ maxLength: 6 }}
                    />
                  </FormControl>

                  <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                      id="signup-password-input"
                      label="Password"
                      type={passVisibility ? 'text' : 'password'}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      readOnly={formInProgress}
                      fullWidth 
                      inputProps={{ maxLength: 50 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {passVisibility ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="password">Password Confirmation</InputLabel>
                    <OutlinedInput
                      id="signup-password-confirmation-input"
                      label="Confirm Password"
                      type={passVisibilityConfirmation ? 'text' : 'password'}
                      value={passwordConfirmation}
                      onChange={e => setPasswordConfirmation(e.target.value)}
                      readOnly={formInProgress}
                      fullWidth 
                      inputProps={{ maxLength: 50 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPasswordConfirmation}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {passVisibilityConfirmation ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </>
              }
            </div>

            <div className="forgot-password-error">
              {formError}
            </div>

            <div className="submit__row">
              {!verificationMode && !emailSentStep1 && 
                <div className="submit__forgot">
                  <span className="submit__forgot--text">
                    <a onClick={() => showFormStep2()}>I have a code!</a>
                  </span>
                </div>
              }

              <div className="submit__btn">
                {!verificationMode &&
                  <>
                    {emailSentStep1 &&
                      <Button
                        id="enter-code-button"
                        variant="outlined"
                        className={`button-outline-login spacer ${(isDisabled) ? 'button--outlined-login-disabled' : ''}`}
                        disabled={isDisabled}
                        onClick={() => showFormStep2()}
                      >
                        ENTER CODE
                      </Button>
                    }
                    <Button
                      id="send-button"
                      variant="contained"
                      className={`button-login spacer ${(isDisabled) ? 'button-login-disabled' : ''}`}
                      disabled={isDisabled}
                      onClick={() => sendEmailForm()}
                    >
                      SEND
                    </Button>
                  </>
                }
                {verificationMode && passwordChanged && 
                  <Button
                    variant="contained"
                    className={`button-login spacer ${(isDisabledVerification) ? 'button-login-disabled' : ''}`}
                    disabled={isDisabledVerification}
                    onClick={() => redirectTo('/login')}
                  >
                    GO TO LOGIN
                  </Button>
                }
                {verificationMode && !passwordChanged && 
                  <>
                    <Button
                      variant="outlined"
                      className="button-outline-login spacer"
                      onClick={() => showFormStep1()}
                    >
                      GO BACK
                    </Button>
                    <Button
                      variant="contained"
                      className={`button-login spacer ${(isDisabledVerification) ? 'button-login-disabled' : ''}`}
                      disabled={isDisabledVerification}
                      onClick={() => confirmVerificationCode()}
                    >
                      CONFIRM
                    </Button>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordPage;