import React from 'react';
import { Box, Typography } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

export function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress sx={{ borderRadius: "10px" }} color="success" variant="determinate" {...props} />
      </Box>

      <Box sx={{ minWidth: 35 }}>
        <div>
          <Typography variant="body2"><b>{`${Math.round(
            props.value,
          )}%`} usage</b></Typography></div>
      </Box>
    </Box>
  );
}

export function LinearProgressWithValue(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Box sx={{ width: '100%', mr: 1 }} >
        <LinearProgress sx={{ borderRadius: "10px" }} color="inherit" variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2"><b>{`${Math.round(
          props.value,
        )}%`}</b></Typography>
      </Box>
    </Box>
  );
}
