import React, { useEffect, useState } from 'react';
import { addNewNote } from '../../api/LCMApi';
import {
  FormControl,  OutlinedInput, Button, InputAdornment, Grid, Tooltip, Alert, AlertColor, 
} from '@mui/material';
import { useIsMounted } from '../../helpers/global-helpers';
import './NotesAddPage.scss';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface NotesAddPageInterface {
  data: {
    licenseId: string;
    content: string;
  },
  refreshAction: any;
  onClose: any;
}

const NotesAddPage = (props: NotesAddPageInterface) => {
  const isMounted = useIsMounted();

  const [content, setContent] = useState(props.data.content);
  const [formError, setFormError] = useState<{ text: string; type: AlertColor; }>({ text: '', type: 'error' });
  const [formInProgress, setFormInProgress] = useState(false);

  const maxContentLength = 255;

  const closeAction = () => {
    props.onClose();
  }

  async function confirmAddNote() {
    if (!content){
      setFormError({ text: 'Add content!', type: 'error' });
      return;
    }

    // Add Note
    setFormInProgress(true);
    setFormError({ text: 'Adding note...', type: 'warning' });
    const response = await addNewNote({
      licenseId: props.data.licenseId,
      content: content,
      type: '',
    });

    if (!isMounted.current) return;

    if (!response.status){
      setFormInProgress(false);
      setFormError({ text: 'Error adding note...', type: 'error' });
      return;
    }
  
    setFormInProgress(false);
    setFormError({ text: 'Note added succesfully...', type: 'success' });
    //showToastMessage('Note added succesfully...', 'success', { id: 'add-note' });
    props.refreshAction(props.data.licenseId, response.data);
    closeAction();
}

  useEffect(() => {
    if (!isMounted.current) return;
    if (!props.data) return;

    setContent(props.data.content);
  }, [props.data]);

  return (
    <div className="main-form">
      <div className="form-title">Add new note</div>
      <div className="form-body">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="form-label">Contents</div>
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
              <OutlinedInput
                id="content"
                value={content}
                onChange={e => {
                  const valid = e.target.value.length <= maxContentLength;
                  if (valid) setContent(e.target.value);
                }}
                fullWidth
                inputProps={{ maxLength: maxContentLength }}
                multiline
                autoFocus
                endAdornment={
                  (content || formError.text) &&
                  <InputAdornment position="end">
                      {content.length >= 3 ? 
                        <Tooltip title="Validated!">
                          <CheckCircleIcon className="green" />
                        </Tooltip>
                      :
                        <Tooltip title="Write some content!">
                          <ErrorIcon className="red" />
                        </Tooltip>
                      }
                  </InputAdornment>
                }
              />
            </FormControl>
            <div className="form-label">{maxContentLength - content.length} characters left</div>
          </Grid>
          <Grid item xs={8} className="form-error form-alert-error">
            {formError.text && 
              <Alert severity={formError.type}>{formError.text}</Alert>
            }
          </Grid>
          <Grid item xs={4}>
            <div className="form-actions">
              <Button
                id="add-note-confirm"
                className="button-outline form-button"
                variant="outlined"
                style={{
                  backgroundColor: (!formInProgress ? "#ffffff" : "#bbbbbb"),
                  color: (!formInProgress ? "#999999" : "#999999"),
                }}
                disabled={formInProgress}
                onClick={() => closeAction()}
              >
                CANCEL
              </Button>
              <Button
                id="add-note-confirm"
                className="button form-button"
                variant="contained"
                style={{
                  backgroundColor: (!formInProgress ? "#077764" : "#bbbbbb"),
                  color: (!formInProgress ? "#ffffff" : "#999999"),
                }}
                disabled={formInProgress}
                onClick={() => confirmAddNote()}
              >
                CONFIRM
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default NotesAddPage;
