import React from 'react'
import Layout from '../components/Layout'
import './UserAccessPage.scss';

function UserAccessPage() {
    return (
        <Layout>
            <div className="dashBoard__title">
                User Access
            </div>
        </Layout>
    )
}

export default UserAccessPage
