import React, { useState } from 'react';
import { AppContext } from './AppContext'
import { AppDefaultData } from './AppDefaultData';

const AppContextProvider = (props) => {

  const [data, setData] = useState(AppDefaultData);
  const setContextData = (newData) => {
    //if (typeof(data[property]) === undefined) return;

    setData({
      ...data,
      ...newData,
    });
  }

  return (
    <AppContext.Provider value={{ contextData: data, setContextData }}>
      {props.children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
