import axios from 'axios';
import { getAccessTokenSessionStorage } from '../helpers/global-helpers';
const api = process.env.REACT_APP_API_URL;

export async function getLicenseUsersUsage() {
  let returnValue = { status: false, message: '', data: null };
  await axios
    .get(`${api}/users/usage`, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    })
    .then(
      function (response) {
        if (!response.status) {
          const message =
            response.data && response.data.message
              ? response.data.message
              : 'License data usage couldn\'t be obtained!';
          returnValue.message = message;
          return;
        }

        // Check every user
        returnValue.status = true;
        returnValue.message = '';
        returnValue.data = response.data;
      },
      (error) => {
        returnValue.message = 'License data usage couldn\'t be obtained!';
      }
    );

  return returnValue;
}

export async function getLicenseProgramsUsage() {
  let returnValue = { status: false, message: '', data: null };
  await axios
    .get(`${api}/programs/usage`, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    })
    .then(
      function (response) {
        if (!response.status) {
          const message =
            response.data && response.data.message
              ? response.data.message
              : 'Error obtaining license usage!';
          returnValue.message = message;
          return;
        }

        // Check every user
        returnValue.status = true;
        returnValue.message = '';
        returnValue.data = response.data;
      },
      (error) => {
        returnValue.message = 'Error obtaining license usage!';
      }
    );

  return returnValue;
}

export async function getLicenseTypes() {
  let returnValue = { status: false, message: '', data: null };
  await axios
    .get(`${api}/licenses-types`, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    })
    .then(
      function (response) {
        if (!response.status) {
          const message =
            response.data && response.data.message
              ? response.data.message
              : 'Error obtaining license type list!';
          returnValue.message = message;
          return;
        }

        // Check every user
        returnValue.status = true;
        returnValue.message = '';
        returnValue.data = response.data;
      },
      (error) => {
        returnValue.message = 'Error obtaining license type list!';
      }
    );

  return returnValue;
}

export async function getLicenses() {
  try {
    const response = await axios
      .get(`${api}/licenses`, {
        headers: {
          Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
        },
      });

    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getLicensesValue() {
  let returnValue = { status: false, message: '', data: null };
  await axios.get(`${api}/licenses`, {
    headers: {
      Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
    },
  })
  .then(
    function (response) {
      if (!response.status) {
        const message =
          response.data && response.data.message
            ? response.data.message
            : 'Error obtaining Licenses!';
        returnValue.message = message;
        return returnValue;
      }

      // Check every user
      returnValue.status = true;
      returnValue.message = '';
      returnValue.data = response.data;
    },
    (error) => {
      returnValue.message = 'Error obtaining Licenses!';
    }
  );

  return returnValue;
}

export async function getLicensesByOrganization(organizationName) {
  let returnValue = { status: false, message: '', data: null };
  await axios
    .get(`${api}/licenses/byOrganization/${organizationName}`, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    })
    .then((response) => {
      if (!response.status) {
        const message =
          response.data && response.data.message
            ? response.data.message
            : 'Error obtaining Organizations!';
        returnValue.message = message;
      }else{
        // Check every user
        returnValue.status = true;
        returnValue.message = '';
        returnValue.data = response.data;
      }
    })
    .catch((error) => {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Error obtaining Organizations!. Server unavailable.';
      returnValue.message = message;
    });

  return returnValue;
}

export async function getUsers() {
  let returnValue = { status: false, message: '', data: null };
  await axios
    .get(`${api}/users`, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    })
    .then(
      function (response) {
        if (!response.status) {
          const message =
            response.data && response.data.message
              ? response.data.message
              : 'Error obtaining users!';
          returnValue.message = message;
          return;
        }

        // Check every user
        returnValue.status = true;
        returnValue.message = '';
        returnValue.data = response.data;
      },
      (error) => {
        returnValue.message = 'Error obtaining users!';
      }
    );

  return returnValue;
}

export async function addNewUsers(users) {
  let returnValue = { status: false, message: '', data: [] };
  await axios
    .post(
      `${api}/users`,
      {
        users: users,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
        },
      }
    )
    .then((response) => {
      if (!response.status) {
        returnValue.message = 'Error adding users!';
        return;
      }
      // Check every user
      returnValue.status = true;
      returnValue.message = 'Users added!';
      returnValue.data = response.data;
    })
    .catch(function (error) {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Users not added!. Server unavailable.';
      returnValue.message = message;
    });

  return returnValue;
}

interface ResendInvitationInterface {
  status: boolean;
  message: string;
}
export async function resendInvitation(
  id: string,
  email: string
): Promise<ResendInvitationInterface> {
  if (!id) return { status: false, message: 'No user id!' };

  let result = { status: false, message: '' };

  await axios
    .post(
      `${api}/users/resend`,
      {
        email: email,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
        },
      }
    )
    .then(function (response) {
      if (response.status) {
        result.status = true;
        return;
      }

      const message =
        response.data && response.data.message
          ? response.data.message
          : "Invitation could not be send!";
      result.message = message;
    })
    .catch(function (error) {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Invitation not sent!. Server unavailable.';
      result.message = message;
    });

  return result;
}

interface DeleteUserInterface {
  status: boolean;
  message: string;
}
export async function deleteUser(id: string): Promise<DeleteUserInterface> {
  if (!id) return { status: false, message: 'No user id!' };

  let result = { status: false, message: '' };

  await axios
    .delete(`${api}/users/${id}`, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    })
    .then(function (response) {
      if (response.status) {
        result.status = true;
        return;
      }

      const message =
        response.data && response.data.message
          ? response.data.message
          : "User couldn't be deleted!";
      result.message = message;
    })
    .catch(function (error) {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'User not deleted!. Server unavailable.';
      result.message = message;
    });

  return result;
}

interface SuspendUserInterface {
  status: boolean;
  message: string;
}
export async function suspendUser(id: string): Promise<SuspendUserInterface> {
  if (!id) return { status: false, message: 'No user id!' };

  let result = { status: false, message: '' };

  await axios
    .post(
      `${api}/users/${id}/disable`,
      {
        id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
        },
      }
    )
    .then(function (response) {
      if (response.status) {
        result.status = true;
        return;
      }

      const message =
        response.data && response.data.message
          ? response.data.message
          : "User couldn't be suspended!";
      result.message = message;
    })
    .catch(function (error) {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'User not suspended!. Server unavailable.';
      result.message = message;
    });

  return result;
}

export async function unsuspendUser(id: string): Promise<SuspendUserInterface> {
  if (!id) return { status: false, message: 'No user id!' };

  let result = { status: false, message: '' };

  await axios
    .post(
      `${api}/users/${id}/enable`,
      {
        id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
        },
      }
    )
    .then(function (response) {
      if (response.status) {
        result.status = true;
        return;
      }

      const message =
        response.data && response.data.message
          ? response.data.message
          : "User couldn't be unsuspended!";
      result.message = message;
    })
    .catch(function (error) {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'User not unsuspended!. Server unavailable.';
      result.message = message;
    });

  return result;
}

interface EditUserDataInterface {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}
interface EditUserInterface {
  status: boolean;
  message: string;
}
export async function editUser(userData: EditUserDataInterface): Promise<EditUserInterface> {
  if (!userData.id) return { status: false, message: 'No user id!' };

  let result = { status: false, message: '' };

  await axios
    .put(
      `${api}/users/${userData.id}`,
      {
        id: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
        },
      }
    )
    .then(function (response) {
      if (response.status) {
        result.status = true;
        return;
      }

      const message =
        response.data && response.data.message
          ? response.data.message
          : "User Roles couldn't be updated!";
      result.message = message;
    })
    .catch(function (error) {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'User roles not updated!. Server unavailable.';
      result.message = message;
    });

  return result;
}

/* PROGRAMS */
export async function getPrograms(programId?) {
  let returnValue = { status: false, message: '', data: null };
  const url = programId ? `${api}/programs/${programId}` : `${api}/programs`;
  await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    })
    .then((response) => {
      if (!response.status) {
        returnValue.message = 'Error getting programs from organization!';
        return;
      }
      // Check every user
      returnValue.status = true;
      returnValue.message = '';
      returnValue.data = response.data;
    })
    .catch(function (error) {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Error obtaining programs!. Server unavailable.';
      returnValue.message = message;
    });

  return returnValue;
}

export async function getProgramData(programId) {
  try {
    return await axios.get(`${api}/programs/${programId}`, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

export async function getCSVNames(programId, datasetName, file) {
  try {
    return await axios.post(
      `${api}/csv/companies/full?programId=${programId}&datasetName=${datasetName}`,
      {
        file: file,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
        },
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getProgramDataSet(dataSetId) {
  try {
    return await axios.get(
      `${api}/import-results?datasetId=${dataSetId}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
        },
      }
    );
  } catch (error) {
    console.log(error);
  }
}

interface CreateUpdateDeleteProgramInterface {
  status: boolean;
  message: string;
  data?: any;
}
export async function createProgram(programData): Promise<CreateUpdateDeleteProgramInterface> {
  let returnValue = { status: false, message: "", data: null };
  if (!programData) return { status: false, message: "Program data empty!" };
  if (!programData.name) return { status: false, message: "Program name empty!" };
  if (!programData.description) return { status: false, message: "Program description empty!" };
  if (!programData.responsibleUser) return { status: false, message: "Program responsible User not found!" };
  if (!programData.assignedUsers) return { status: false, message: "No assigned users for the program!" };

  await axios
    .post(`${api}/programs`, programData, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    })
    .then((response) => {
      if (!response.status) {
        returnValue.message = 'Error creating new program on organization!';
        return;
      }
      // Check every user
      returnValue.status = true;
      returnValue.message = '';
      returnValue.data = { id: response.data.id };
    })
    .catch(function (error) {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Program not created!. Server unavailable.';
      returnValue.message = message;
    });

  return returnValue;
}

export async function updateProgram(programData): Promise<CreateUpdateDeleteProgramInterface> {
  let returnValue = { status: false, message: "" };
  if (!programData) return { status: false, message: "Program data empty!" };
  if (!programData.id) return { status: false, message: "Program data empty!" };
  if (!programData.name) return { status: false, message: "Program name empty!" };
  if (!programData.description) return { status: false, message: "Program description empty!" };
  if (!programData.responsibleUser) return { status: false, message: "Program responsible User not found!" };
  if (!programData.assignedUsers) return { status: false, message: "No assigned users for the program!" };

  await axios
    .put(`${api}/programs/${programData.id}`, programData, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    })
    .then((response) => {
      if (!response.status) {
        returnValue.message = 'Error updating program on organization!';
        return;
      }
      // Check every user
      returnValue.status = true;
      returnValue.message = '';
    })
    .catch(function (error) {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Program not updated!. Server unavailable.';
      returnValue.message = message;
    });

  return returnValue;
}

export async function deleteProgram(programId): Promise<CreateUpdateDeleteProgramInterface> {
  let returnValue = { status: false, message: "" };
  if (!programId) return { status: false, message: "No Program id given!" };

  await axios
    .delete(`${api}/programs/${programId}/soft`, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    })
    .then((response) => {
      if (!response.status) {
        returnValue.message = 'Error deleting program on organization!';
        return;
      }
      // Check every user
      returnValue.status = true;
      returnValue.message = '';
    })
    .catch(function (error) {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Program not deleted!. Server unavailable.';
      returnValue.message = message;
    });

  return returnValue;
}

export async function addNewOrganization(data) {
  let returnValue = { status: false, message: '', data: [] };
  await axios
    .post(
      `${api}/license-wizard`,
      data,
      {
        headers: {
          Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
        },
      }
    )
    .then((response) => {
      if (!response.status) {
        returnValue.message = 'Error adding Organization!';
        return;
      }
      // Check every user
      returnValue.status = true;
      returnValue.message = 'Organization added!';
      returnValue.data = response.data;
    })
    .catch(function (error) {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Organization not added!. Server unavailable.';
      returnValue.message = message;
    });

  return returnValue;
}

export async function getLicenseTemplate() {
  let returnValue = { status: false, message: '', data: null };
  await axios
    .get(`${api}/licenses`, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    })
    .then(
      function (response) {
        if (!response.status) {
          const message =
            response.data && response.data.message
              ? response.data.message
              : 'License templates couldn\'t be obtained!';
          returnValue.message = message;
          return;
        }

        // Check every user
        returnValue.status = true;
        returnValue.message = '';
        returnValue.data = response.data;
      },
      (error) => {
        console.log('license type templates ==> ', error);
        returnValue.message = 'License templates couldn\'t be obtained!';
      }
    );

  return returnValue;
}
export async function getLicenseById(id: string) {
  let returnValue = { status: false, message: '', data: null };
  console.log('id:', id)
  await axios
    .get(`${api}/licenses/${id}`, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    })
    .then(
      function (response) {
        if (!response.status) {
          const message =
            response.data && response.data.message
              ? response.data.message
              : 'License templates couldn\'t be obtained!';
          returnValue.message = message;
          return;
        }

        // Check every user
        returnValue.status = true;
        returnValue.message = '';
        returnValue.data = response.data;
      },
      (error) => {
        console.log('license type templates ==> ', error);
        returnValue.message = 'License templates couldn\'t be obtained!';
      }
    );

  return returnValue;
}

export const updateTemplateData = (tempData) => {
  axios.put(`${api}/licenses/${tempData[0].id}`, tempData[0], {
    headers: {
      Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      'Content-Type': 'application/json'
    }
  })
  .then((res) => console.log(res.data))
  .catch((error) => console.log(error))
}
export const createTemplateData = (tempData) => {
  axios.post(`${api}/licenses`, tempData, {
    headers: {
      Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
    }
  })
  .then((res) => console.log(res.data))
  .catch((error) => console.log(error))
}

export async function addNewNote(note) {
  let returnValue = { status: false, message: '', data: [] };
  await axios
    .post(
      `${api}/notes`,
      {
        content: note.content,
      },
      {
        params: {
          licenseId: note.licenseId,
        },
        headers: {
          Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
        },
      }
    )
    .then((response) => {
      if (!response.status) {
        returnValue.message = 'Error adding note!';
        return;
      }
      // Check every user
      returnValue.status = true;
      returnValue.message = 'Note added!';
      returnValue.data = response.data;
    })
    .catch(function (error) {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Note not added!. Server unavailable.';
      returnValue.message = message;
    });

  return returnValue;
}

interface DeleteNoteInterface {
  status: boolean;
  message: string;
}

export async function deleteNote(id: string): Promise<DeleteNoteInterface> {
  if (!id) return { status: false, message: 'No user id!' };

  let result = { status: false, message: '' };

  await axios
    .delete(`${api}/notes/${id}`, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    })
    .then(function (response) {
      if (response.status) {
        result.status = true;
        return;
      }

      const message =
        response.data && response.data.message
          ? response.data.message
          : "Note couldn't be deleted!";
      result.message = message;
    })
    .catch(function (error) {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Note not deleted!. Server unavailable.';
      result.message = message;
    });

  return result;
}

interface AddDocumentinterface {
  id: string;
  documentFile: any;
  handleUploadProgress: any;
}
interface AddDocumentResultInterface {
  status: boolean;
  message: string;
  data?: any;
}

export async function addDocument(props: AddDocumentinterface): Promise<AddDocumentResultInterface> {
  if (!props.id) return { status: false, message: 'No license id!' };
  if (!props.documentFile) return { status: false, message: 'No file sent!' };

  let returnValue = { status: false, message: '', data: null };

  const data = new FormData();
  data.append('file', props.documentFile);

  await axios
  .post(`${api}/documents`, data, {
    params:{
      licenseId: props.id
    },
    headers: {
      Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
    },
    onUploadProgress: (progressEvent) => {
      props.handleUploadProgress(progressEvent);
    },
  })
  .then((response) => {
    if (!response.status) {
      returnValue.message = 'Error uploading new document on organization!';
      return;
    }
    // Check every user
    returnValue.status = true;
    returnValue.message = '';
    returnValue.data = response.data;
  })
  .catch(function (error) {
    const message =
      error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Document not uploaded!. Server unavailable.';
    returnValue.message = message;
  });

  return returnValue;
}

export async function downloadDocument(id): Promise<AddDocumentResultInterface> {
  if (!id) return { status: false, message: 'No document id!', data: null };

  let returnValue = { status: false, message: '', data: null };

  await axios
  .get(`${api}/documents/${id}/download`, {
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
    },
  })
  .then((response) => {
    if (!response.status) {
      returnValue.message = 'Error downloading document!';
      return;
    }
    // Check every user
    returnValue.status = true;
    returnValue.message = '';
    returnValue.data = response;
  })
  .catch(function (error) {
    const message =
      error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Document cannot be downloaded!. Server unavailable.';
    returnValue.message = message;
  });

  return returnValue;
}

export async function deleteDocument(id): Promise<AddDocumentResultInterface> {
  if (!id) return { status: false, message: 'No license id!' };

  let returnValue = { status: false, message: '', data: null };

  await axios
  .delete(`${api}/documents/${id}`, {
    headers: {
      Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
    },
  })
  .then((response) => {
    if (!response.status) {
      returnValue.message = 'Error deleting document on organization!';
      return;
    }
    // Check every user
    returnValue.status = true;
    returnValue.message = '';
  })
  .catch(function (error) {
    const message =
      error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Document not deleted!. Server unavailable.';
    returnValue.message = message;
  });

  return returnValue;
}

interface DeleteLicenseInterface {
  status: boolean;
  message: string;
}
export async function DeleteLicense(id: string): Promise<DeleteLicenseInterface> {
  if (!id) return { status: false, message: 'No License id!' };

  let result = { status: false, message: '' };

  await axios
    .delete(`${api}/licenses/${id}`, {
      headers: {
        Authorization: `Bearer ${getAccessTokenSessionStorage()}`,
      },
    })
    .then(function (response) {
      if (response.status) {
        result.status = true;
        return;
      }

      const message =
        response.data && response.data.message
          ? response.data.message
          : "License couldn't be deleted!";
      result.message = message;
    })
    .catch(function (error) {
      const message =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'License not deleted!. Server unavailable.';
      result.message = message;
    });

  return result;
} 
