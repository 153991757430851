import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Auth from '@aws-amplify/auth';
import { AppContext } from '../context/AppContext';
import CustomModal from '../components/custom-modal/CustomModal';
import { FormControl, Checkbox, InputLabel, OutlinedInput, InputAdornment, IconButton, Button } from '@mui/material';
import { useIsMounted, nameRegExp, passwordRegExp } from '../helpers/global-helpers';
import { termsConditionsContent, privacyPolicyContent } from '../helpers/TermsPrivacyContent';

/**************************************
** Component styles
***************************************/
import { EmailOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import './SignUpPage.scss';

export interface SignUpProps {
  email?: string;
}

export function SignUpPage(props: SignUpProps): JSX.Element {
  const isMounted = useIsMounted();
  const { contextData, setContextData } = useContext(AppContext);

  // Read Context Data values
  const { userAuthData } = contextData;
  const phoneNumber = userAuthData?.challengeParam?.userAttributes['custom:phoneNumber'] || '';

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passVisibility, setPassVisibility] = useState(false);
  const [passVisibilityConfirmation, setPassVisibilityConfirmation] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);

  const history = useHistory();
  const [formError, setFormError] = useState('');
  const [formInProgress, setFormInProgress] = useState(false);
  const [formCompleted, setFormCompleted] = useState(false);
  const [signUpExpired, setSignUpExpired] = useState(false);
  const email = userAuthData?.challengeParam?.userAttributes?.email || userAuthData?.username || '';
  const authRequiredAttributes = userAuthData?.challengeParam?.userAttributes;

  const isDisabled: boolean = !firstName || !lastName || !password || !passwordConfirmation || formInProgress ? true : false;

  function handleShowPassword() {
    !formInProgress && setPassVisibility(!passVisibility);
  }

  function handleShowPasswordConfirmation() {
    !formInProgress && setPassVisibilityConfirmation(!passVisibilityConfirmation);
  }

  function handleMouseDownPassword(event){
    event.preventDefault();
  }

  function redirectTo(page) {
    history.push(page);
  }

  async function handleSignUp() {
    // Validate data
    const validFirstName = firstName.match(nameRegExp);
    const validLastName = lastName.match(nameRegExp);
    const validPass = password.match(passwordRegExp);
    const validPassConfirmation = password === passwordConfirmation;

    if (!validFirstName){
      setFormError('Invalid First Name! It should contain at least 2 letters, no numbers and no symbols.');
      return ;
    }

    if (!validLastName){
      setFormError('Invalid Last Name! It should contain at least 2 letters, no numbers and no symbols.');
      return ;
    }

    if (password.length < 8){
      setFormError('Password should contain at least eight characters!');
      return ;
    }

    if (!validPass){
      setFormError('Password should contain at least one uppercase letter, one lowercase letter, one number and one special symbol!');
      return ;
    }

    if (!validPassConfirmation){
      setFormError('Passwords don\'t match!');
      return ;
    }

    if (!termsChecked){
      setFormError('You need to agree with the Terms & Conditions!');
      return ;
    }

    if (!privacyChecked){
      setFormError('You need to agree with the Privacy Policy!');
      return ;
    }

    // Access BE
    setFormError('Sending data...');
    if (passwordChanged){
      updateUserData();
      return;
    }

    setFormInProgress(true);
    await Auth.completeNewPassword(
      userAuthData,
      password,
      authRequiredAttributes,
      {
        'custom:phoneNumber': phoneNumber,
        email: email,
        given_name: firstName,
        family_name: lastName,
      }
    )
    .then((result) => {
      setFormError('Password changed successfully! Proceed to change user data...');
      setPasswordChanged(true);
      updateUserData();
    })
    .catch((e) => {
      setFormInProgress(false);
      if (e){
        // Check for Session invalid
        if (e.code === 'NotAuthorizedException'){
          setFormError('Session expired!. You\'ll need to login again to continue.');
          setSignUpExpired(true);
          return;
        }
        if (e.code === 'InvalidParameterException'){
          // Try to save data anyway
          setPasswordChanged(true);
          updateUserData();
          return;
        }

        setFormError('Error while updating user!. Please try again.');
        return;
      }
    });
  }

  async function updateUserData(){
    let resultUserData;
    try{
      resultUserData = await Auth.updateUserAttributes(
        userAuthData, {
          given_name: firstName,
          family_name: lastName,
        }
      );
    }catch(e){
      setFormInProgress(false);
      if (e){
        setFormError('Error while updating user data!. Please try again.');
        return;
      }
    }

    if (resultUserData){
      setFirstName('');
      setLastName('');
      setPassword('');
      setPasswordConfirmation('');
      setFormError('Password and user data changed successfully! Proceed to login.');
      setFormCompleted(true);
      return;
    }

    setFormError('Error while updating user data!. Please try again.');
  }

  useEffect(() => {
    if (!isMounted.current) return;

    // Check validity of user auth data
    if (!userAuthData || !email){
      redirectTo('/login');
    }
  }, []);

  return (
    <div id="signup" className="signup">
      <div className="header">
        <a href="/"><img
          id="header-logo"
          src="./images/logos/alchemai-edge_desktop-logo.png"
          className="logo"
          alt="Alchemai EDGE"
        /></a>
      </div>
      <div className="content">
        <CustomModal
          id="terms-conditions-modal"
          title="Terms & Conditions"
          open={termsModalOpen}
          handleClose={() => setTermsModalOpen(false)}
        >
          <div className="terms-privacy-title">
            <h3>Terms & Conditions</h3>
          </div>

          <div className="terms-privacy-content">{termsConditionsContent}</div>

          <div className="terms-privacy-footer">
            <Button
              id="terms-decline-button"
              variant="outlined"
              className="button-outline-login"
              onClick={() => { setTermsChecked(false); setTermsModalOpen(false); }} 
            >Decline</Button>

            <Button 
              id="terms-accept-button"
              variant="contained"
              className="button-login"
              onClick={() => { setTermsChecked(true); setTermsModalOpen(false); }} 
            >Accept</Button>
          </div>
        </CustomModal>
        <CustomModal
          id="privacy-policy-modal"
          title="Privacy Policy"
          open={privacyModalOpen}
          handleClose={() => setPrivacyModalOpen(false)}
        >
          <div className="terms-privacy-title">
            <h3>Privacy Policy</h3>
          </div>

          <div className="terms-privacy-content">{privacyPolicyContent}</div>

          <div className="terms-privacy-footer">
            <Button
              id="terms-decline-button"
              variant="outlined"
              className="button-outline-login"
              onClick={() => { setPrivacyChecked(false); setPrivacyModalOpen(false); }}
            >Decline</Button>

            <Button 
              id="terms-accept-button"
              variant="contained"
              className="button-login"
              onClick={() => { setPrivacyChecked(true); setPrivacyModalOpen(false); }} 
            >Accept</Button>
          </div>
        </CustomModal>

        <div className="banner">
          <div className="banner-internal">
            <div className="logo">
              <img 
                id="logo-right"
                src="./images/logos/alchemai-logo-white.png"
                alt="Alchemai Logo"
              />
            </div>
            <div className="title">Access Alchemai EDGE<br/>License Control Manager</div>
            <div className="subtitle">Fill the form to complete the sign up process!</div>
          </div>
        </div>
        <div className="form">
          <div id="login-form" className="login-form">
            <div className="title">Sign Up</div>

            <div className="inputs-fields">
              <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="email">Email Adress</InputLabel>
                <OutlinedInput
                  id="email"
                  label="Email Address"
                  value={email}
                  onChange={e => {}}
                  disabled={formInProgress}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton disabled color="primary" edge="end">
                        <EmailOutlined />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="email">First Name</InputLabel>
                <OutlinedInput
                  id="first-name-input"
                  label="First Name"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  disabled={formInProgress}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              </FormControl>

              <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="email">Last Name</InputLabel>
                <OutlinedInput
                  id="last-name-input"
                  label="Last Name"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  disabled={formInProgress}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              </FormControl>

              <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  id="password"
                  label="Password"
                  type={passVisibility ? 'text' : 'password'}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  readOnly={formInProgress}
                  fullWidth 
                  inputProps={{ maxLength: 50 }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {passVisibility ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="password">Password Confirmation</InputLabel>
                <OutlinedInput
                  id="password-confirmation"
                  label="Password Confirmation"
                  type={passVisibilityConfirmation ? 'text' : 'password'}
                  value={passwordConfirmation}
                  onChange={e => setPasswordConfirmation(e.target.value)}
                  readOnly={formInProgress}
                  fullWidth 
                  inputProps={{ maxLength: 50 }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPasswordConfirmation}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {passVisibilityConfirmation ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <div className="checkboxes">
                <Checkbox
                  id="terms-checkbox"
                  checked={termsChecked}
                  value="on"
                  disabled={formInProgress}
                  className="checkbox-terms"
                  onChange={(e) => setTermsChecked(e.target.checked)}
                />
                <span className="checkboxes__text" onClick={() => setTermsChecked(!termsChecked)}>I agree to the </span>
                <span id="terms-checkbox-modal" className="checkboxes__link" onClick={() => setTermsModalOpen(true)}>Terms & Conditions</span>
              </div>

              <div className="checkboxes">
                <Checkbox
                  id="privacy-checkbox"
                  checked={privacyChecked}
                  value="on"
                  disabled={formInProgress}
                  className="checkbox-terms"
                  onChange={(e) => setPrivacyChecked(e.target.checked)}
                />
                <span className="checkboxes__text" onClick={() => setPrivacyChecked(!privacyChecked)}>I agree to the </span>
                <span id="privacy-checkbox-modal" className="checkboxes__link" onClick={() => setPrivacyModalOpen(true)}>Privacy Policy</span>
              </div>

            </div>

            <div className="login-error">
              {formError}
            </div>

            <div className="submit__row">
              <div className="submit__btn">
                {(formCompleted || signUpExpired) &&
                  <Button
                    id="continue-button"
                    variant="outlined"
                    className="button-outline-login"
                    onClick={() => redirectTo('/')}
                  >
                    CONTINUE
                  </Button>
                }
                {!signUpExpired && 
                  <Button
                    id="sign-in"
                    variant="contained"
                    className={`button-login ${(isDisabled) ? 'button-login-disabled' : ''}`}
                    disabled={isDisabled}
                    onClick={handleSignUp}
                  >
                    SIGN UP
                  </Button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpPage;