import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { clearUserDataToSessionStorage } from '../helpers/global-helpers'
import Auth from '@aws-amplify/auth';
import { AppContext } from '../context/AppContext';
import { Box, LinearProgress } from '@mui/material';

export function LogoutPage(): JSX.Element {
  const history = useHistory();
  const { setContextData } = useContext(AppContext);

  function redirectTo(page) {
    history.push(page);
  }

  async function clearUserData(){
    clearUserDataToSessionStorage();
    await Auth.signOut();
    setContextData({ isAuthed: false });
    redirectTo('/login');
  }

  useEffect(() => {
    clearUserData();
  })

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  );
}

export default LogoutPage;
