import React, { useEffect, useState } from 'react';
import {
  Grid, Button, FormControl, OutlinedInput, InputLabel, Select, MenuItem, InputAdornment, IconButton, Tooltip, 
} from '@mui/material';
import Layout from '../../components/Layout';
import CustomModal from '../../components/custom-modal/CustomModal';
import PromptModal from '../../components/custom-modal/PromptModal';
import OrganizationModel from './OrganizationModel';
import NotesAddPage from './NotesAddPage';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { FileUpload } from '../../components/file-upload/file-upload';
import { 
  getLicenseTypes, getLicensesValue, getLicensesByOrganization, deleteNote, 
  addDocument, downloadDocument, deleteDocument
} from '../../api/LCMApi';
import {
  useIsMounted, showToastMessage, hideToastMessage, beautifyConstrain,
  copyTextToClipboard, documentFileTypes
} from '../../helpers/global-helpers';

import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import './OrganizationPage.scss';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: '1px solid #d4d4d4',
  '&:last-child': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

let licenseSelected: any = null;
let licenseNoteSelected: any = null;
let licenseDocumentSelected: any = null;

export default function OrganizationPage() {
  const isMounted = useIsMounted();

  const [loadingData, setLoadingData] = useState(true);
  const [errorInData, setErrorInData] = useState(false);
  const [licenseToFilter, setLicenseToFilter] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterLicense, setFilterLicense] = useState('');
  const [licenseOrderBy, setLicenseOrderBy] = useState({ field: 'organization', mode: 'asc' });

  const [licenses, setLicenses] = useState([]);
  const [licenseTypes, setLicenseTypes] = useState([]);
  const [filteredLicenses, setFilteredLicenses] = useState([]);

  const [addOrganizationModalOpened, setAddOrganizationModalOpened] = useState(false);
  const [editOrganizationModalOpened, setEditOrganizationModalOpened] = useState(false);
  const [deleteLicensePromptOpened, setDeleteLicensePromptOpened] = useState(false);

  const [addNoteModalOpened, setAddNoteModalOpened] = useState(false);
  const [deleteNotePromptOpened, setDeleteNotePromptOpened] = useState(false);

  const [addDocumentUploadingProgress, setAddDocumentUploadingProgress] = useState(0);
  const [addDocumentUploadFinished, setAddDocumentUploadFinished] = useState(false);
  const [deleteDocumentPromptOpened, setDeleteDocumentPromptOpened] = useState(false);

  const getLicensesType = async () => {
    const licensesType = await getLicenseTypes();

    if (!isMounted.current) return;

    if (licensesType.status){
      setLicenseTypes(licensesType.data);
    }
  }

  const getLicensesData = async () => {
    setLoadingData(true);
    let licensesData = [];
    const response = await getLicensesValue();

    if (!isMounted.current) return;

    if (!response.status){
      setErrorInData(true);
      setLicenses([]);
      setLoadingData(false);
      return;
    }

    if (!response.data || response.data.length <= 0){
      setLicenses([]);
      setLoadingData(false);
      return;
    }

    licensesData = response.data;
    // Set up known constrains
    licensesData.forEach((license, index) => {
      license.maxPrograms = null;
      license.maxUsers = null;
      license.details = null;
      license.loadingDetails = false;
      license.selected = false;
      license.mainIndex = index;

      // find number of programs and users
      license.constraints && license.constraints.forEach((constrain) => {
        switch (constrain.name){
          case 'MAX_PROGRAMS':
            license.maxPrograms = constrain.value;
            return;

          case 'MAX_USERS':
            license.maxUsers = constrain.value;
            return;
        }
      });
    });

    // Set up Licenses and filters them
    setLicenses(licensesData);
    filterLicenses(licensesData);

    // Get all Licenses
    let types = [];
    let typesList = [];
    licensesData.forEach((license) => {
      if (!types[license.name]){
        types[license.name] = license.name;
        typesList.push(license.name);
      }
    });
    setLicenseToFilter(typesList);

    setLoadingData(false);
  }

  const getLicenseDetails = async (currentLicense) => {
    currentLicense.details = null;
    currentLicense.loadingDetails = true;
    const response = await getLicensesByOrganization(currentLicense.organization);

    if (!isMounted.current) return;

    if (!response.status || !response.data){
      currentLicense.details = null;
      currentLicense.loadingDetails = false;
    }else{
      // Set data in license object
      currentLicense.details = true;
      currentLicense.notes = response.data?.notes || [];
      currentLicense.documents = response.data?.documents || [];
      currentLicense.organizationDetails = response.data.organization;
      currentLicense.loadingDetails = false;
    }

    // Set Licenses with new data
    licenses.forEach((license) => {
      if (currentLicense.id === license.id){
        license.details = currentLicense.details;
        license.loadingDetails = currentLicense.loadingDetails;
      }
    });
    filterLicenses();
  };

  const filterLicenses = (licenseListToUse?: any[]) => {
    let licensesData = [];
    let licenseList = (licenseListToUse) ? licenseListToUse : licenses;

    if (!filterName && !filterLicense){
      licenseList = applyOrderBy(licenseList.slice());
      setFilteredLicenses(licenseList);
      return;
    }

    licenseList.forEach((license) => {
      let validOption = true;
      if (filterName && license.organization.toLocaleLowerCase().indexOf(filterName.toLocaleLowerCase()) <= -1){
        validOption = false;
      }

      if (validOption && filterLicense && license.name.toLocaleLowerCase() !== filterLicense.toLocaleLowerCase()){
        validOption = false;
      }

      if (validOption) licensesData.push(license);
    });

    licensesData = applyOrderBy(licensesData);
    setFilteredLicenses(licensesData);
  }

  const applyOrderBy = (licensesData) => {
    licensesData.sort((a, b) => {
      if (licenseOrderBy.field === 'organization'){
        if (licenseOrderBy.mode === 'asc'){
          return (a.organization.toLowerCase() < b.organization.toLowerCase()) ? -1 : 1;
        }else{
          return (a.organization.toLowerCase() >= b.organization.toLowerCase()) ? -1 : 1;
        }
      }

      if (licenseOrderBy.field === 'type'){
        if (licenseOrderBy.mode === 'asc'){
          return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1;
        }else{
          return (a.name.toLowerCase() >= b.name.toLowerCase()) ? -1 : 1;
        }
      }

      if (licenseOrderBy.field === 'expiration'){
        if (licenseOrderBy.mode === 'asc'){
          return (a.daysUntilExpiration < b.daysUntilExpiration) ? -1 : 1;
        }else{
          return (a.daysUntilExpiration >= b.daysUntilExpiration) ? -1 : 1;
        }
      }

      if (licenseOrderBy.field === 'max-users'){
        if (licenseOrderBy.mode === 'asc'){
          return (a.maxUsers < b.maxUsers) ? -1 : 1;
        }else{
          return (a.maxUsers >= b.maxUsers) ? -1 : 1;
        }
      }

      return 1;
    });
    return licensesData;
  };

  const selectLicense = (license, index, status, openMode = true) => {
    license.selected = status;
    const licensesData = licenses.slice();
    licensesData[index] = license;
    setLicenses(licensesData);

    if (openMode){
      // Check loaded details
      if (!license.details){
        getLicenseDetails(license);
      }
    }

    licenseSelected = license;
  };

  const setLicenseOrder = (orderBy) => {
    switch(orderBy){
      case 'organization':
      case 'type':
      case 'expiration':
      case 'max-users':
        setLicenseOrderBy({
          field: orderBy, 
          mode: (licenseOrderBy.field === orderBy && licenseOrderBy.mode === 'asc') ? 'desc' : 'asc'
        });
        return;
    }
  };

  const selectLicenseNote = (note) => {
    licenseNoteSelected = note;
  };

  async function deleteLicense(){
    setDeleteLicensePromptOpened(false);
    showToastMessage('License cannot be deleted at this time!', 'error', { id: 'organizations' });

    /*showToastMessage('Deleting license...', 'loading', { id: 'organizations' });
    const result = await deleteLicense();
    if (!isMounted.current) return;

    if (!result.status){
      showToastMessage('Error deleting note...', 'error', { id: 'organizations' });
      return;
    }

    showToastMessage('License deleted!', 'success', { id: 'organizations' });
    */
  }

  async function deleteOrganizationNote(licenseId, noteId){
    setDeleteNotePromptOpened(false);

    showToastMessage('Deleting note...', 'loading', { id: 'organizations' });
    const result = await deleteNote(noteId);
    if (!isMounted.current) return;

    if (!result.status){
      showToastMessage('Error deleting note...', 'error', { id: 'organizations' });
      return;
    }

    showToastMessage('Note deleted!', 'success', { id: 'organizations' });

    // Remove note from license
    const licensesData = removeNoteFromLicense(licenseId, noteId);
    setLicenses(licensesData);
    filterLicenses(licensesData);
  }
  const removeNoteFromLicense = (licenseId, noteId) => {
    // Remove note from license
    const licensesData = licenses.slice();
    licensesData.forEach((license) => {
      if (license.id === licenseId){
        for (let i = license.notes.length - 1; i >= 0; i--){
          if (license.notes[i].id === noteId){
            license.notes.splice(i, 1);
          }
        }
      }
    });

    return licensesData;
  };

  const addNoteToLicense = (licenseId, note) => {
    // Add note to license
    const licensesData = licenses.slice();
    licensesData.forEach((license) => {
      if (license.id === licenseId){
        license.notes.push({
          id: note.id,
          createdDate: note.createdDate,
          content: note.content,
          type: note.type,
        });
      }
    });

    setLicenses(licensesData);
    filterLicenses(licensesData);
  };

  const selectLicenseDocument = (document) => {
    licenseDocumentSelected = document;
  };

  async function addDocumentToLicense(file){
    setAddDocumentUploadFinished(false);
    setAddDocumentUploadingProgress(0);
    const currentLicense = licenseSelected;

    const result = await addDocument({ 
      id: currentLicense.id,
      documentFile: file,
      handleUploadProgress: (e) => {
        if (e.type === "progress"){
          const percent = Math.floor(e.loaded / e.total * 100);
          setAddDocumentUploadingProgress(percent);
          console.log(e);
        }
      }
    });

    if (!isMounted.current) return;

    if (!result.status){
      setAddDocumentUploadFinished(true);
      showToastMessage('Error uploading document...', 'error', { id: 'organizations' });
      return;
    }

    showToastMessage('Document uploaded!', 'success', { id: 'organizations' });
    setAddDocumentUploadFinished(true);

    // Add document from license
    const document = result.data;
    const licensesData = addDocumentToList(currentLicense.id, document);
    setLicenses(licensesData);
    filterLicenses(licensesData);
    getLicenseDetails(currentLicense);
  };

  async function downloadDocumentFile(fileName, id){
    const currentLicense = licenseSelected;
    showToastMessage('Downloading document...', 'loading', { id: 'organizations' });

    const result = await downloadDocument(id);

    if (!isMounted.current) return;

    if (!result.status || !result.data){
      setAddDocumentUploadFinished(true);
      showToastMessage('Error uploading document...', 'error', { id: 'organizations' });
      return;
    }

    // Create download element
    var file = new File([result.data.data], fileName);

    //var data = new Blob([result.data.data], {type: result.data.headers['content-type']});
    var fileURL = window.URL.createObjectURL(file);
    const tempLink = document.createElement('a');
    tempLink.href = fileURL;
    tempLink.setAttribute('download', fileName);
    tempLink.click();
    showToastMessage('Document downloaded!', 'success', { id: 'organizations' });
  }

  const addDocumentToList = (licenseId, document) => {
    // Remove document from license
    const licensesData = licenses.slice();
    licensesData.forEach((license) => {
      if (license.id === licenseId){
        license.documents.push(document);
      }
    });

    return licensesData;
  };

  const removeDocumentFromLicense = (licenseId, documentId) => {
    // Remove document from license
    const licensesData = licenses.slice();
    licensesData.forEach((license) => {
      if (license.id === licenseId){
        for (let i = license.documents.length - 1; i >= 0; i--){
          if (license.documents[i].id === documentId){
            license.documents.splice(i, 1);
          }
        }
      }
    });

    return licensesData;
  };

  async function deleteOrganizationDocument(licenseId, documentId){
    setDeleteDocumentPromptOpened(false);
    showToastMessage('Deleting document...', 'loading', { id: 'organizations' });

    const result = await deleteDocument(documentId);

    if (!isMounted.current) return;

    if (!result.status){
      showToastMessage('Error deleting document...', 'error', { id: 'organizations' });
      return;
    }

    showToastMessage('Document deleted!', 'success', { id: 'organizations' });

    // Remove document from license
    const licensesData = removeDocumentFromLicense(licenseId, documentId);
    setLicenses(licensesData);
    filterLicenses(licensesData);
  }

  useEffect(() => {
    if (!isMounted.current) return;

    getLicensesType();
    getLicensesData();

    return(() => {
      hideToastMessage();
    })
  }, []);

  useEffect(() => {
    if (!isMounted.current) return;
    if (licenses.length <= 0) return;

    filterLicenses();
  }, [filterName, filterLicense, licenseOrderBy.field, licenseOrderBy.mode]);

  return (

    <Layout>
      <CustomModal
        id="create-organization-modal"
        title="Create new Organization"
        shiftedRight={true}
        open={addOrganizationModalOpened}
        handleClose={() => setAddOrganizationModalOpened(false)}
      >
        <OrganizationModel
          mode="add"
          licenses={licenseTypes}
          handleCloseModal={() => setAddOrganizationModalOpened(false)}
          refreshOrganizationList={getLicensesData}
        />
      </CustomModal>

      <CustomModal
        id="edit-organization-modal"
        title="Edit Organization details"
        shiftedRight={true}
        open={editOrganizationModalOpened}
        handleClose={() => setEditOrganizationModalOpened(false)}
      >
        <OrganizationModel
          mode="edit"
          data={licenseSelected}
          licenses={licenseTypes}
          handleCloseModal={() => setEditOrganizationModalOpened(false)}
          refreshOrganizationList={getLicensesData}
        />
      </CustomModal>

      <CustomModal
        id="add-note"
        title={`Add note to '${licenseSelected?.organization}'`}
        shiftedRight={true}
        open={addNoteModalOpened}
        handleClose={() => setAddNoteModalOpened(false)}
      >
        <NotesAddPage
          data={{
            licenseId: licenseSelected?.id,
            content: '',
          }}
          refreshAction={(licenseId, data) => addNoteToLicense(licenseId, data)}
          onClose={() => setAddNoteModalOpened(false)}
        />
      </CustomModal>

      <PromptModal
        id="delete-license"
        title="Delete License?"
        text={`Do you confirm deleting the license '${licenseSelected?.organization}'?`}
        shiftedRight={true}
        open={deleteLicensePromptOpened}
        handleConfirm={() => deleteLicense()}
        handleClose={() => setDeleteLicensePromptOpened(false)}
      />

      <PromptModal
        id="delete-note"
        title="Delete Note?"
        text={`Do you confirm deleting this note from '${licenseSelected?.organization}'?`}
        shiftedRight={true}
        open={deleteNotePromptOpened}
        handleConfirm={() => deleteOrganizationNote(licenseSelected.id, licenseNoteSelected.id)}
        handleClose={() => setDeleteNotePromptOpened(false)}
      />

      <PromptModal
        id="delete-document"
        title="Delete Document?"
        text={`Do you confirm deleting thes document '${licenseDocumentSelected?.originalFilename}' from '${licenseSelected?.organization}'?`}
        shiftedRight={true}
        open={deleteDocumentPromptOpened}
        handleConfirm={() => deleteOrganizationDocument(licenseSelected.id, licenseDocumentSelected.id)}
        handleClose={() => setDeleteDocumentPromptOpened(false)}
      />

      <div className="main-section">
        <div className="main-table">
          <div className="table-title no-margin">
            <div className="title">Existing organizations with license</div>
            <div className="actions">
              <Button
                id="button-new-organization"
                variant="outlined"
                className="button-outline"
                onClick={() => setAddOrganizationModalOpened(true)}
              >
                NEW ORGANIZATION FORM
              </Button>
            </div>
          </div>

          {!loadingData && licenses.length > 0 &&
            <div className="table-filter">
              <div className="table-filter-column" style={{ width: '74%', paddingRight: '1%' }}>
                <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                  <InputLabel htmlFor="filter-name" style={{ fontSize: '0.8em' }}>Filter by organization</InputLabel>
                  <OutlinedInput
                    id="filter-name"
                    value={filterName}
                    label="Filter by organization"
                    onChange={e => setFilterName(e.target.value)}
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    style={{ fontSize: '0.8em' }}
                    endAdornment={
                      filterName &&
                      <InputAdornment position="end">
                          {filterName && 
                            <Tooltip title="Clear">
                              <IconButton
                                aria-label="clear"
                                onClick={() => setFilterName('')}
                                onMouseDown={(e) => e.preventDefault()}
                                edge="end"
                              >
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          }
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="table-filter-column" style={{ width: '25%' }}>
                <FormControl sx={{ my: 1, width: '100%' }} variant="outlined" fullWidth>
                  <InputLabel htmlFor="filter-status" style={{ fontSize: '0.8em' }}>Filter by License</InputLabel>
                  <Select
                    id="filter-license"
                    value={filterLicense}
                    label="Filter by license"
                    style={{ fontSize: '0.8em' }}
                    onChange={e => setFilterLicense(e.target.value)}
                  >
                    {licenseToFilter.length <= 0 &&
                      <MenuItem value="">No license types found!</MenuItem>
                    }
                    {licenseToFilter.length > 0 &&
                      <MenuItem value="">No filter</MenuItem>
                    }
                    {licenseToFilter.map((licenseType, index) => {
                      return <MenuItem key={index} value={licenseType}>{licenseType}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          }

          <div className="table-content">
            {loadingData && 
              <div className="table-loading">
                Loading organizations data...
              </div>
            }

            {!loadingData && 
              <div className="table-header">
                <Grid container spacing={0}>
                  <Grid item xs={3.8} className="show-pointer no-select" onClick={() => setLicenseOrder('organization')}>COMPANY</Grid>
                  <Grid item xs={2.2} className="show-pointer no-select centered" onClick={() => setLicenseOrder('type')}>LICENSE TYPE</Grid>
                  <Grid item xs={2.2} className="show-pointer no-select centered" onClick={() => setLicenseOrder('expiration')}>EXPIRATION</Grid>
                  <Grid item xs={2.2} className="show-pointer no-select centered" onClick={() => setLicenseOrder('max-users')}>MAX USERS</Grid>
                  <Grid item xs={1.6} className="no-select centered">ACTIONS</Grid>
                </Grid>
              </div>
            }

            {!loadingData && errorInData && 
              <div className="table-loading">
                Error loading Organizations! Please try again later.
              </div>
            }

            {!loadingData && !errorInData && filteredLicenses.map((license) => (
              <Accordion
                key={license.id}
                className="organizations-list"
                TransitionProps={{ unmountOnExit: true }}
                expanded={license.selected} 
                onChange={() => selectLicense(license, license.mainIndex, !license.selected)}
              >
                <AccordionSummary className="organizations-summary" aria-controls="organizations-summary" id={`company-${license.id}`}>
                  <Grid className="organizations-summary-grid no-border" container spacing={2}>
                    <Grid item xs={3.8} className="list-organization-name">{license.organization}</Grid>
                    <Grid item xs={2.2} className="list-organization-common"><span className="pill">{license.name}</span></Grid>
                    <Grid item xs={2.2} className="list-organization-common" style={{ textAlign: 'right' }}>
                      {license.daysUntilExpiration > 30 && <span>{license.daysUntilExpiration} days</span>}
                      {license.daysUntilExpiration > 1 && license.daysUntilExpiration <= 30 && <span className="pill pill-yellow">1 day</span>}
                      {license.daysUntilExpiration === 1 && <span className="pill pill-yellow">1 day</span>}
                      {license.daysUntilExpiration <= 0 && <span className="pill pill-red">EXPIRED</span>}
                    </Grid>
                    <Grid item xs={2.2} className="list-organization-common" style={{ textAlign: 'center' }}>
                      {license.maxUsers >= 1 && <span>{license.maxUsers}</span>}
                      {license.maxUsers <= 0 && <span>-</span>}
                    </Grid>
                    <Grid item xs={1.6} className="table-actions">
                      <Tooltip title="View details">
                        <IconButton onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          selectLicense(license, license.mainIndex, true);
                        }}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                      {/*<Tooltip title="Edit License">
                        <IconButton onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          selectLicense(license, license.mainIndex, true, false);
                          setEditOrganizationModalOpened(true);
                        }}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>*/}
                      <Tooltip title="Cancel License">
                        <IconButton onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          selectLicense(license, license.mainIndex, true, false);
                          setDeleteLicensePromptOpened(true);
                        }}>
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className="organizations-details">
                {license.loadingDetails &&
                    <div className="table-loading">
                      Loading Organization data...
                    </div>
                  }
                  {!license.loadingDetails && !license.details && 
                    <div className="table-loading">
                      <div className="license-details-error">
                        Error loading Organization details! Please try again.
                      </div>
                      <div className="license-details-retry">
                        <Button
                          id="reload-license-details"
                          variant="contained"
                          className="button-login fs-11"
                          onClick={() => selectLicense(license, license.mainIndex, true)}
                        >
                          RETRY
                        </Button>
                      </div>
                    </div>
                  }
                  {!license.loadingDetails && license.details && 
                    <Grid container spacing={1} className="main-grid">
                      <Grid item xs={7.5}>
                        <div className="section_title">License ID</div>
                        <div className="fs-12 uppercase">
                          {license.id}
                          <Tooltip title="Copy to clipboard" className="table-actions">
                            <IconButton onClick={() => {
                              copyTextToClipboard(license.id);
                              showToastMessage('Copied to clipboard!');
                            }}>
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div className="section_spacer"></div>

                        <div className="section_title">ADDRESS</div>
                        <div className="fs-12 uppercase">{license.organizationDetails.address.street}</div>
                        <div className="fs-12 uppercase">{license.organizationDetails.address.city} - {license.organizationDetails.address.state}</div>
                        <div className="fs-12 uppercase">{license.organizationDetails.address.country}</div>
                        <div className="section_spacer"></div>

                        <div className="section_title">CONTRACT</div>
                        <div className="fs-12 uppercase">{beautifyConstrain(license.contractLength)}</div>
                        <div className="fs-12 uppercase">{beautifyConstrain(license.paymentTerm)} - {beautifyConstrain(license.renewalOption)}</div>

                        <div className="section_line"></div>

                        <div className="section_title">NOTES</div>
                        {(!license.notes || license.notes.length <= 0) && 
                          <div className="section_message fs-12"> NO NOTES ADDED YET</div>
                        }
                        {license.notes && license.notes.length > 0 && license.notes.map((note) => (
                          <Grid key={note.id} container spacing={2} className="notes-list fs-12">
                            <Grid item xs={2} className="notes-date">{note.createdDate.substring(0, 10)}</Grid>
                            <Grid item xs={9} className="notes-content">{note.content}</Grid>
                            <Grid item xs={1} className="notes-actions">
                              {note.type.toUpperCase() === "MANUAL" && 
                                <Tooltip title="Delete Note" className="table-actions">
                                  <IconButton
                                    onClick={() => {
                                      selectLicense(license, license.mainIndex, true);
                                      selectLicenseNote(note);
                                      setDeleteNotePromptOpened(true);
                                    }}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </Tooltip>
                              }
                              {note.type.toUpperCase() !== "MANUAL" && 
                                <Tooltip title="Automatic notes cannot be deleted" className="table-actions-disabled">
                                  <ClearIcon />
                                </Tooltip>
                              }
                            </Grid>
                          </Grid>
                        ))}

                        <div>
                          <Button
                            id="add-note"
                            variant="contained"
                            className="button-login fs-11"
                            onClick={() => {
                              selectLicense(license, license.mainIndex, true, false);
                              setAddNoteModalOpened(true);
                            }}
                          >
                            ADD NOTE
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={4.5}>
                        <div className="section_title">ATTACH DOCUMENTS</div>
                        <div className="fs-11">Attach up to 5 documents (like a copy of the contract)</div>
                        <div className="attachments">
                          <FileUpload 
                            title="Drop or click to upload a valid document (doc, pdf, xls)."
                            maximumSize={16800000}
                            supportMultipleFiles={false}
                            supportFileTypes={documentFileTypes}
                            uploadDocumentCallback={addDocumentToLicense}
                            uploadingProgress={addDocumentUploadingProgress}
                            finishUpload={addDocumentUploadFinished}
                          />
                        </div>
                        <div className="section_spacer"></div>
                        <div className="section_title">DOCUMENTS</div>
                        {(!license.documents || license.documents.length <= 0) && 
                          <div className="section_message fs-12"> NO DOCUMENTS ADDED YET</div>
                        }
                        {license.documents && license.documents.map((document) => (
                          <Grid key={document.id} container spacing={2} className="documents-list fs-12">
                            <Grid item xs={10} className="documents-content">{document.originalFilename}</Grid>
                            <Grid item xs={1} className="documents-actions">
                              <Tooltip title="Download Document" className="table-actions">
                                <IconButton onClick={() => {
                                  downloadDocumentFile(document.originalFilename, document.id);
                                }}>
                                  <DownloadIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={1} className="documents-actions">
                              <Tooltip title="Delete Document" className="table-actions">
                                <IconButton onClick={() => {
                                  selectLicense(license, license.mainIndex, true);
                                  selectLicenseDocument(document);
                                  setDeleteDocumentPromptOpened(true);
                                }}>
                                  <ClearIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  }
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </Layout >
  );
}
