export const renewalOptions = [
    { value: "NOT_RENEWABLE", displayName: "NOT_RENEWABLE" },
    { value: "ANNUALLY", displayName: "Annually" },
    { value: "BI-ANNUALLY", displayName: "Bi-Annually" }
];

export const contractLengthOptions = [
    { value: "DAYS_60", displayName: "60 Days" },
    { value: "YEARS_1", displayName: "1 Year" },
    { value: "YEARS_2", displayName: "2 Year" }
];

export const paymentTermOptions = [
    { value: "MONTHLY",  displayName: "Monthly" },
    { value: "QUARTERLY", displayName: "Quarterly" },
];

export const maxNoOfUsersOptions = [
    { value: 50, displayName: 50 },
    { value: 100, displayName: 100 },
    { value: 250, displayName: 250 },
    { value: 500, displayName: 500 },
    { value: "UNLIMITED", displayName: "unlimited" },
];

export const maxNoOfProgramsOptions = [
    { value: 1, displayName: 1 },
    { value: 2, displayName: 2 },
    { value: 3, displayName: 3 },
    { value: 4, displayName: 4 },
    { value: 5, displayName: 5 },
    { value: "UNLIMITED", displayName: "unlimited" },
];