import React from 'react';
import AppBar from '@mui/material/AppBar';
import { useHistory } from 'react-router';
import { IconButton, Tooltip } from '@mui/material';
import { getUserDataFromSessionStorage } from '../helpers/global-helpers';
import exportCSV from '../assets/images/export_csv.png';
import LogoutIcon from '@mui/icons-material/Logout';
import './header.scss';

function HeaderDashboard() {
  const history = useHistory();
  const user = getUserDataFromSessionStorage();

  const exportCSVData = () => {

  }

  const logOut = () => {
    history.push('/logout');
  }

  return (
    <div>
      <AppBar className="headerSection" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <div className="mainInner">
          <div className="right-header">
            <div className="csv-down">
              <Tooltip title="Export CSV file">
                <IconButton onClick={() => exportCSVData()}>
                  <img className="export_csv" src={exportCSV} />
                </IconButton>
              </Tooltip>
            </div>
            <div className="back-arrwo">
              <Tooltip title="Log Out">
                <IconButton onClick={() => logOut()}>
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </AppBar>
    </div>
  )
}

export default HeaderDashboard;
