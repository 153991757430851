import React, { useEffect, useState } from 'react';
import {
  FormControl,  OutlinedInput, Select, MenuItem, ListItemText,
  Button, InputAdornment, Grid, Tooltip, Checkbox,
} from '@mui/material';
import Auth from '@aws-amplify/auth';
import {
  useIsMounted, emailRegExp, showToastMessage, hideToastMessage, 
  validateName, validateEmail, validatePhoneNumber,
} from '../../helpers/global-helpers';
import { editUser } from '../../api/LCMApi';
import './UsersEditPage.scss';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface UsersEditPageInterface {
  userData: {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  },
  validateEmail: any;
  refreshUserList: any;
  onClose: any;
}

const UsersEditPage = (props: UsersEditPageInterface) => {
  const isMounted = useIsMounted();

  const [firstName, setFirstName] = useState(props.userData.firstName);
  const [lastName, setLastName] = useState(props.userData.lastName);
  const [email, setEmail] = useState(props.userData.email);
  const [phoneNumber, setPhoneNumber] = useState(props.userData.phoneNumber);

  const [formError, setFormError] = useState('');
  const [formInProgress, setFormInProgress] = useState(false);

  let enabledForm:boolean = firstName !== '' && lastName !== '' && email !== '';

  const closeAction = () => {
    props.onClose();
  }

  async function confirmEditUser() {
    // Validate every field
    if (!validateName(firstName)){
      setFormError('Complete name!');
      return;
    }

    if (!validateName(lastName)){
      setFormError('Complete lastname!');
      return;
    }

    if (!email){
      setFormError('Complete email!');
      return;
    }

    if (!validateEmail(email)){
      setFormError('Email is not valid!');
      return;
    }

    const emailAlreadyExist = props.validateEmail(email);
    if (emailAlreadyExist){
      setFormError('Email is already registered as a user!');
      return;
    }

    if (!validatePhoneNumber(phoneNumber)){
      setFormError('Phone number is not valid!');
      return;
    }

    // Add user
    setFormInProgress(true);
    showToastMessage('Editing user...', 'loading', { id: 'edit-user' })

    const newUserData = {
      id: props.userData.id,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,

    };
    const response = await editUser(newUserData);

    /*try {
      const result = await Auth. (user, {
        "family_name": lastName,
        "given_name": firstName,
      });

      if (result.toString() === 'SUCCESS') {
        showToastMessage('Data Updated Successfully!', 'success', { id: 'profile_message' });
      } else {
        showToastMessage('Something went wrong!', 'error', { id: 'profile_message' });
      }
    } catch (e) {
      showToastMessage(e.message.toString(), 'error', { id: 'profile_message' });
    }

    setFormInProgress(false);
  }*/

    if (!isMounted.current) return;
    if (response.status){
      showToastMessage('User updated!', 'success', { id: 'edit-user' })
      setFormInProgress(false);
      closeAction();
    }

    showToastMessage('Error editing user...', 'error', { id: 'edit-user' })
    setFormInProgress(false);
  }

  useEffect(() => {
    if (!isMounted.current) return;
    if (!props.userData) return;

    setFirstName(props.userData.firstName);
    setLastName(props.userData.lastName);
    setEmail(props.userData.email);
    setPhoneNumber(props.userData.phoneNumber);
  
    return(() => {
      hideToastMessage();
    })
  }, [props.userData]);

  return (
    <div className="main-form">
      <div className="form-title">User Information</div>
      <div className="form-body">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="form-label">Name</div>
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
              <OutlinedInput
                id="firstName"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                disabled={formInProgress}
                fullWidth
                inputProps={{ maxLength: 100 }}
                endAdornment={
                  firstName &&
                  <InputAdornment position="end">
                      {validateName(firstName) ?
                        <Tooltip title="Validated!">
                          <CheckCircleIcon className="green" />
                        </Tooltip>
                      :
                      <Tooltip title="Please enter a valid name!">
                        <ErrorIcon className="red" />
                      </Tooltip>
                    }
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <div className="form-label">Lastname</div>
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
              <OutlinedInput
                id="lastName"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                disabled={formInProgress}
                fullWidth
                inputProps={{ maxLength: 100 }}
                endAdornment={
                  lastName &&
                  <InputAdornment position="end">
                      {validateName(lastName) ?
                        <Tooltip title="Validated!">
                          <CheckCircleIcon className="green" />
                        </Tooltip>
                      :
                        <Tooltip title="Please enter a valid lastname!">
                          <ErrorIcon className="red" />
                        </Tooltip>
                      }
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div className="form-label">Email</div>
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
              <OutlinedInput
                id="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                disabled={formInProgress}
                fullWidth
                inputProps={{ maxLength: 100 }}
                endAdornment={
                  email &&
                  <InputAdornment position="end">
                      {validateEmail(email) ? 
                        <Tooltip title="Validated!">
                          <CheckCircleIcon className="green" />
                        </Tooltip>
                      :
                        <Tooltip title="Not a valid email!">
                          <ErrorIcon className="red" />
                        </Tooltip>
                      }
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div className="form-label">Phone Number</div>
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
              <OutlinedInput
                id="phoneNumber"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                disabled={formInProgress}
                fullWidth
                inputProps={{ maxLength: 100 }}
                endAdornment={
                  phoneNumber &&
                  <InputAdornment position="end">
                      {validatePhoneNumber(phoneNumber) ? 
                        <Tooltip title="Validated!">
                          <CheckCircleIcon className="green" />
                        </Tooltip>
                      :
                        <Tooltip title="Not a valid phone number!">
                          <ErrorIcon className="red" />
                        </Tooltip>
                      }
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} className="form-error">
            {formError}
          </Grid>
          <Grid item xs={6}>
            <div className="form-actions">
              <Button
                id="edit-user-confirm"
                className="button form-button"
                variant="outlined"
                style={{
                  backgroundColor: (!formInProgress ? "#077764" : "#bbbbbb"),
                  color: (!formInProgress ? "#ffffff" : "#999999"),
                }}
                disabled={formInProgress}
                onClick={() => closeAction()}
              >
                CANCEL
              </Button>
              <Button
                id="edit-user-confirm"
                className="button"
                variant="contained"
                style={{
                  backgroundColor: (enabledForm ? "#077764" : "#bbbbbb"),
                  color: (enabledForm ? "#ffffff" : "#999999"),
                }}
                disabled={!enabledForm || formInProgress}
                onClick={() => confirmEditUser()}
              >
                CONFIRM
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default UsersEditPage;
