import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import {
  FormControl,  OutlinedInput, Select, MenuItem, ListItemText, Alert,
  Button, InputAdornment, Grid, Tooltip, Checkbox, InputLabel, IconButton, 
} from '@mui/material';
import { getLicenseTypes, addNewOrganization } from '../../api/LCMApi';
import {
  useIsMounted, emailRegExp, showToastMessage, hideToastMessage, countriesList,
  validateName, validateNameNoSpaces, validateEmail, validatePhoneNumber, validateURL, validateTextLength, 
  validatePostalCode, validateStartDate,
} from '../../helpers/global-helpers';
import './OrganizationModel.scss';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface OrganizationModelProps {
  mode: string;
  data?: any;
  licenses?: any[];
  handleCloseModal: any;
  refreshOrganizationList?: any;
}

function OrganizationModel(props: OrganizationModelProps) {
  const isMounted = useIsMounted();

  const handleCloseModal = props.handleCloseModal;
  const refreshOrganizationList = props?.refreshOrganizationList;

  const addMode = props?.mode === 'add';
  const viewMode = props?.mode === 'view';
  const editMode = props?.mode === 'edit';

  const currentOrganization = props?.data;
  const [name, setName] = useState(currentOrganization?.organization || '');
  const [website, setWebsite] = useState(currentOrganization?.website || '');
  const [licenseTypeId, setLicenseTypeId] = useState(currentOrganization?.name || '');
  const [startDate, setStartDate] = useState(currentOrganization?.startDate || '');

  const [contactSectionCounter, updateContactSectionCounter] = useState([0]);
  const [licensesTypes, setLicensesTypes] = useState(props.licenses || []);
  const [address, setAddress] = useState({
    id: "",
    street: "",
    city: "",
    country: "",
    state: "",
    postalCode: "",
  });

  const [contacts, setContacts] = useState<any>([{
    email: "",
    phoneNumber: "",
    isAdmin: false,
  }]);

  const [formInProgress, setFormInProgress] = useState(false);
  const [formError, setFormError] = useState('');

  useEffect(() => {
    if (!isMounted.current) return;
    if (props.licenses) return;

    const getLicensesTypeData = async () => {
      const licensesTypeData = await getLicenseTypes();
      if (licensesTypeData && licensesTypeData.data) {
        setLicensesTypes(licensesTypeData.data);
      }
    }

    getLicensesTypeData();
  }, [])

  useEffect(() => {
    if (!isMounted.current) return;
    if (!licensesTypes.length) return;

    setLicenseTypeId(licensesTypes[0]?.id);
  }, [licensesTypes]);

  async function handleSubmit(){
    const payload = {
      organization: {
        name: name,
        website: website,
        address: address,
        contacts: contacts,
      },
      licenseTypeId,
      startDate,
    }

    setFormError('');

    if (!validateNameNoSpaces(name)){
      setFormError('Organization name is not valid!');
      return;
    }

    if (!validateTextLength(address['street'], 2)){
      setFormError('Street in adress is not valid!');
      return;
    }

    if (!validateTextLength(address['city'], 2)){
      setFormError('City is not valid!');
      return;
    }

    if (!address['country']){
      setFormError('Pick one country from the list!');
      return;
    }

    if (!validateTextLength(address['state'], 2)){
      setFormError('State is not valid!');
      return;
    }

    if (!validatePostalCode(address['postalCode'])){
      setFormError('Postal code is not valid!');
      return;
    }

    if (!licenseTypeId){
      setFormError('Pick one license type from the list!');
      return;
    }

    // Check every contact
    contacts.forEach((contact, index) => {
      if (!validateEmail(contact.email)){
        setFormError(`Contact email #${index} is not valid!`);
        return;
      }
      if (!validatePhoneNumber(contact.phoneNumber)){
        setFormError(`Contact phone number #${index} is not valid!`);
        return;
      }
    });
    
    // Check data
    setFormInProgress(true);
    const response = await addNewOrganization(payload);
    if (!response.status){
      setFormInProgress(false);
      setFormError(response.message || 'Organization could not be added!');
      return;
    }

    showToastMessage('Organization created succesfully!', 'success', { id: 'organization' });
    setFormInProgress(false);
    refreshOrganizationList && refreshOrganizationList();
    handleCloseModal();
  };

  return (
    <div className="organization-wrp main-form">
      <div className="organization-wrp-title"><b>Company information </b></div>
      <div className="organization-content-row form-body">
        <div className="organization-content-col-6">
          <div className="form-label">Company Name:</div>
          <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
            <OutlinedInput
              id="companyName"
              value={name}
              onChange={e => setName(e.target.value)}
              disabled={formInProgress}
              readOnly={viewMode}
              fullWidth
              inputProps={{ maxLength: 100 }}
              endAdornment={
                !viewMode && (name || formError) &&
                <InputAdornment position="end">
                    {validateNameNoSpaces(name) ?
                      <Tooltip title="Validated!">
                        <CheckCircleIcon className="green" />
                      </Tooltip>
                    :
                    <Tooltip title="Please enter a valid company name!">
                      <ErrorIcon className="red" />
                    </Tooltip>
                  }
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="organization-content-col-6">
          <div className="form-label">Company website:</div>
          <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
            <OutlinedInput
              id="companyWebsite"
              value={website}
              onChange={e => setWebsite(e.target.value)}
              disabled={formInProgress}
              readOnly={viewMode}
              fullWidth
              inputProps={{ maxLength: 100 }}
              endAdornment={
                !viewMode && (website || formError) &&
                <InputAdornment position="end">
                    {validateURL(website) ?
                      <Tooltip title="Validated!">
                        <CheckCircleIcon className="green" />
                      </Tooltip>
                    :
                      <Tooltip title="Please enter a valid website link!">
                        <ErrorIcon className="red" />
                      </Tooltip>
                    }
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>
      <div className="organization-wrp-title"><b>Company Address </b></div>
      <div className="organization-content-row form-body">
        <div className="organization-content-col-6">
          <div className="form-label">Street and number:</div>
          <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
            <OutlinedInput
              id="street"
              value={address['street']}
              onChange={(e) => setAddress({ ...address, 'street': e.target.value })}
              disabled={formInProgress}
              readOnly={viewMode}
              fullWidth
              inputProps={{ maxLength: 100 }}
              endAdornment={
                !viewMode && (address['street'] || formError) &&
                <InputAdornment position="end">
                    {validateTextLength(address['street'], 2) ?
                      <Tooltip title="Validated!">
                        <CheckCircleIcon className="green" />
                      </Tooltip>
                    :
                      <Tooltip title="Please enter a valid address!">
                        <ErrorIcon className="red" />
                      </Tooltip>
                    }
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="organization-content-col-6">
          <div className="form-label">City:</div>
          <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
            <OutlinedInput
              id="city"
              value={address['city']}
              onChange={(e) => setAddress({ ...address, 'city': e.target.value })}
              disabled={formInProgress}
              readOnly={viewMode}
              fullWidth
              inputProps={{ maxLength: 50 }}
              endAdornment={
                !viewMode && (address['city'] || formError) &&
                <InputAdornment position="end">
                    {validateTextLength(address['city'], 2) ?
                      <Tooltip title="Validated!">
                        <CheckCircleIcon className="green" />
                      </Tooltip>
                    :
                      <Tooltip title="Please enter a valid City!">
                        <ErrorIcon className="red" />
                      </Tooltip>
                    }
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="organization-content-col-6">
          <div className="form-label">Country:</div>
          {viewMode &&
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
              <OutlinedInput
                id="country-view-mode"
                value={address['country']}
                readOnly={true}
                fullWidth
              />
            </FormControl>
          } 
          {(addMode || editMode) &&
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined" fullWidth>
              <Select
                id="country"
                value={address['country']}
                style={{ fontSize: '0.8em' }}
                onChange={(e) => setAddress({ ...address, 'country': e.target.value })}
                endAdornment={
                  (address['country'] || formError) &&
                  <InputAdornment position="end">
                      {address['country'] ?
                        <Tooltip title="Validated!">
                          <CheckCircleIcon className="green" />
                        </Tooltip>
                      :
                        <Tooltip title="Please select one country!">
                          <ErrorIcon className="red" />
                        </Tooltip>
                      }
                  </InputAdornment>
                }
              >
                {countriesList.map((country, index) => {
                  return <MenuItem key={index} value={country}>{country}</MenuItem>;
                })}
              </Select>
            </FormControl>
          }
        </div>
        <div className="organization-content-col-3">
          <div className="form-label">State / Province:</div>
          <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
            <OutlinedInput
              id="state"
              value={address['state']}
              onChange={(e) => setAddress({ ...address, 'state': e.target.value })}
              disabled={formInProgress}
              readOnly={viewMode}
              fullWidth
              inputProps={{ maxLength: 50 }}
              endAdornment={
                !viewMode && (address['state'] || formError) &&
                <InputAdornment position="end">
                    {validateTextLength(address['state'], 2) ?
                      <Tooltip title="Validated!">
                        <CheckCircleIcon className="green" />
                      </Tooltip>
                    :
                      <Tooltip title="Please enter a valid State / Province!">
                        <ErrorIcon className="red" />
                      </Tooltip>
                    }
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="organization-content-col-3">
          <div className="form-label">Zip / Postal:</div>
          <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
            <OutlinedInput
              id="postalCode"
              value={address['postalCode']}
              onChange={(e) => setAddress({ ...address, 'postalCode': e.target.value })}
              disabled={formInProgress}
              readOnly={viewMode}
              fullWidth
              inputProps={{ maxLength: 16 }}
              endAdornment={
                !viewMode && (address['postalCode'] || formError) &&
                <InputAdornment position="end">
                    {validatePostalCode(address['postalCode']) ?
                      <Tooltip title="Validated!">
                        <CheckCircleIcon className="green" />
                      </Tooltip>
                    :
                      <Tooltip title="Please enter a valid State / Province!">
                        <ErrorIcon className="red" />
                      </Tooltip>
                    }
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>
      <div className="organization-content-row form-body">
        <div className="organization-content-col-6">
          <div className="organization-wrp-title"><b>License type </b></div>
          <div className="form-label">Select license type:</div>
          {viewMode &&
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
              <OutlinedInput
                id="licenseTypeId-view-mode"
                value={licenseTypeId}
                readOnly={true}
                fullWidth
              />
            </FormControl>
          }
          {(addMode || editMode) &&
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined" fullWidth>
              <Select
                id="licenseTypeId"
                value={licenseTypeId}
                style={{ fontSize: '0.8em' }}
                onChange={(e) => setLicenseTypeId(e.target.value)}
                disabled={formInProgress}
                readOnly={viewMode}
                endAdornment={
                  (licenseTypeId || formError) &&
                  <InputAdornment position="end">
                      {licenseTypeId ?
                        <Tooltip title="Validated!">
                          <CheckCircleIcon className="green" />
                        </Tooltip>
                      :
                        <Tooltip title="Please select one license type!">
                          <ErrorIcon className="red" />
                        </Tooltip>
                      }
                  </InputAdornment>
                }
              >
                {licensesTypes.length <= 0 && 
                  <MenuItem value=''>No license templates available!</MenuItem>
                }
                {licensesTypes.length > 0 && licensesTypes.map((item, index) => {
                  return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          }
        </div>
        <div className="organization-content-col-6">
          <div className="organization-wrp-title"><b>Start date </b></div>
          <div className="form-label">Select license start date:</div>
          <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
            <OutlinedInput
              id="startDate"
              type="date"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
              disabled={formInProgress}
              readOnly={viewMode}
              fullWidth
              inputProps={{ maxLength: 100 }}
              endAdornment={
                !viewMode && (website || formError) &&
                <InputAdornment position="end">
                    {validateStartDate(startDate) ?
                      <Tooltip title="Validated!">
                        <CheckCircleIcon className="green" />
                      </Tooltip>
                    :
                      <Tooltip title="Please select start date!">
                        <ErrorIcon className="red" />
                      </Tooltip>
                    }
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>

      <div className="organization-wrp-title"><b>Contact person </b></div>
      {contactSectionCounter.map((item, index) => {
        return (
          <div key={index}>
            <div className="organization-content-row form-body">
              <div className="organization-content-col-7">
                <div className="form-label">Contact email:</div>
                <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                  <OutlinedInput
                    id="email"
                    value={contacts[index]?.email}
                    onChange={(e) => {
                      const obj = [...contacts];
                      if (obj.length > index) obj[index].email = e.target.value;
                      else obj.push({ email: e.target.value, })
                      setContacts(obj);
                    }}
                    disabled={formInProgress}
                    readOnly={viewMode}
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    endAdornment={
                      !viewMode && (contacts[index]?.email || formError) &&
                      <InputAdornment position="end">
                          {validateEmail(contacts[index]?.email) ?
                            <Tooltip title="Validated!">
                              <CheckCircleIcon className="green" />
                            </Tooltip>
                          :
                            <Tooltip title="Please enter a valid contact email!">
                              <ErrorIcon className="red" />
                            </Tooltip>
                          }
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="organization-content-col-4">
                <div className="form-label">Phone number:</div>
                <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                  <OutlinedInput
                    id="phoneNumber"
                    value={contacts[index]?.phoneNumber}
                    onChange={(e) => {
                      const obj = [...contacts];
                      if (obj.length > index) obj[index].phoneNumber = e.target.value;
                      else obj.push({ phoneNumber: e.target.value, })
                      setContacts(obj);
                    }}
                    disabled={formInProgress}
                    readOnly={viewMode}
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    endAdornment={
                      !viewMode && (contacts[index]?.phoneNumber || formError) &&
                      <InputAdornment position="end">
                          {validatePhoneNumber(contacts[index]?.phoneNumber) ?
                            <Tooltip title="Validated!">
                              <CheckCircleIcon className="green" />
                            </Tooltip>
                          :
                            <Tooltip title="Please enter a valid contact phone number!">
                              <ErrorIcon className="red" />
                            </Tooltip>
                          }
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="organization-content-col-1 organization-content-col-flex">
                {index > 0 && !viewMode &&
                  <Tooltip title="Remove Contact">
                    <IconButton
                      aria-label="clear"
                      disabled={formInProgress}
                      onClick={(e) => {
                        const contactsList = contacts.slice();
                        contactsList.splice(index, 1);
                        setContacts(contactsList);
                        const contactSectionCounterItem = contactSectionCounter.slice();
                        contactSectionCounterItem.splice(index, 1);
                        updateContactSectionCounter(contactSectionCounterItem);
                      }}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Tooltip>
                }
              </div>
            </div>
            <div className="organization-content-row form-body">
              <div className="organization-content-col-12">
                <div className="org-form-box check-boxs">
                  <label className="label-clickable"><input
                    type="checkbox"
                    id="isAdmin"
                    name="isAdmin"
                    disabled={formInProgress}
                    readOnly={viewMode}
                    onChange={(e) => {
                      const obj = [...contacts];
                      if (obj.length > index) obj[index].isAdmin = e.target.checked;
                      else obj.push({ isAdmin: e.target.checked, })
                      setContacts(obj);
                    }}
                  />Make contact an admin </label>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      {(addMode || editMode) &&
        <div className="organization-content-row">
          <div className="organization-content-col-12">
            <div className="org-form-box add-user-pic">
              <label
                className="label-clickable"
                onClick={() => {
                  if (formInProgress) return;
                  updateContactSectionCounter([...contactSectionCounter, contactSectionCounter.length + 1]);
                }}
              >Add additional contact</label>
            </div>
          </div>
        </div>
      }
      <div className="organization-content-row">
        <div className="organization-content-col-8 form-alert-error">
          {formError && 
            <Alert severity="error">{formError}</Alert>
          }
        </div>
        <div className="organization-content-col-4">
          <div className="organization-content-footer">
            {(addMode || editMode) &&
              <Button
                id="create-organization"
                onClick={() => handleSubmit() }
                disabled={formInProgress}
              >
                {addMode ? 'Create Organization' : 'Edit Organization'}
              </Button>
            }
            {viewMode &&
              <Button
                id="close-view-organization"
                onClick={() => handleCloseModal() }
              >
                Close
              </Button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganizationModel;
