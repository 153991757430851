import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip,
  FormControl, OutlinedInput, InputLabel, Select, MenuItem, InputAdornment, Button,
} from '@mui/material';

import Layout from '../components/Layout';
import CustomModal from '../components/custom-modal/CustomModal';
import PromptModal from '../components/custom-modal/PromptModal';
import { getLicenseTypes, getLicenses } from '../api/LCMApi';
import { useIsMounted, showToastMessage, hideToastMessage } from '../helpers/global-helpers';

import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ClearIcon from '@mui/icons-material/Clear';
import './dashboard/DashboardPage.scss';
import LicenseModel from './dashboard/LicenseModel';
import OrganizationModel from './dashboard/OrganizationModel';

export default function LicensePage() {
  const isMounted = useIsMounted();

  const [loadingData, setLoadingData] = useState(true);
  const [errorInData, setErrorInData] = useState(false);
  const [licenseToFilter, setLicenseToFilter] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterLicense, setFilterLicense] = useState('');
  const [licensesOrderBy, setLicensesOrderBy] = useState({ field: 'organization', mode: 'asc' });

  const [constraints, setConstraints] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [licenseTypes, setLicenseTypes] = useState([]);
  const [filteredLicenses, setFilteredLicenses] = useState([]);

  const [organizationData, setOrganizationData] = useState(null);
  const [addOrganizationModalOpened, setAddOrganizationModalOpened] = useState(false);
  const [viewOrganizationModalOpened, setViewOrganizationModalOpened] = useState(false);
  const [editOrganizationModalOpened, setEditOrganizationModalOpened] = useState(false);
  const [deleteOrganizationModalOpened, setDeleteOrganizationModalOpened] = useState(false);
  const [licenseTypeModalOpened, setLicenseTypeModalOpened] = useState(false);

  const handleOpenLicenseModal = () => {
    setLicenseTypeModalOpened(true);
  };
  const handleCloseLicenseModal = () => {
    setLicenseTypeModalOpened(false);
  };

  const getLicensesType = async () => {
    const licensesType = await getLicenseTypes();

    if (!isMounted.current) return;

    if (licensesType.status) {
      setLicenseTypes(licensesType.data);
    }
  }

  const getLicensesData = async () => {
    setLoadingData(true);
    const licensesData = await getLicenses();

    if (!isMounted.current) return;

    if (licensesData && licensesData.length) {
      setConstraints(licensesData[0].constraints.map(constraint => constraint.name).filter(item => item !== "TABLET"));

      // Get all Licenses
      let types = [];
      let typesList = [];
      licensesData.forEach((license) => {
        license.maxUsers = null;
        license.maxPrograms = null;

        // find number of programs and users
        license.constraints && license.constraints.forEach((constrain) => {
          switch (constrain.name) {
            case 'MAX_PROGRAMS':
              license.maxPrograms = constrain.value;
              return;

            case 'MAX_USERS':
              license.maxUsers = constrain.value;
              return;
          }
        });

        if (!types[license.name]) {
          types[license.name] = license.name;
          typesList.push(license.name);
        }
      });

      setLicenses(licensesData);
      filterLicenses(licensesData);
      setLicenseToFilter(typesList);

      setLoadingData(false);
      return;
    }

    setLicenses([]);
    setLoadingData(false);
  }

  const findCellValue = (constraints, name) => {
    const constraint = constraints.find(constraint => constraint.name === name);
    return constraint ? constraint.value : '';
  }

  const filterLicenses = (licenseListToUse?: []) => {
    let licensesData = [];
    const licenseList = (licenseListToUse) ? licenseListToUse : licenses.slice();

    if (!filterName && !filterLicense) {
      licensesData = applyOrderBy(licenseList);
      setFilteredLicenses(licensesData);
      return;
    }

    licenseList.forEach((license) => {
      let validOption = true;
      if (filterName && license.organization.toLocaleLowerCase().indexOf(filterName.toLocaleLowerCase()) <= -1) {
        validOption = false;
      }

      if (validOption && filterLicense && license.name.toLocaleLowerCase() !== filterLicense.toLocaleLowerCase()) {
        validOption = false;
      }

      if (validOption) licensesData.push(license);
    });

    licensesData = applyOrderBy(licensesData);
    setFilteredLicenses(licensesData);
  }

  const applyOrderBy = (licenses) => {
    licenses.sort((a, b) => {
      if (licensesOrderBy.field === 'organization') {
        if (licensesOrderBy.mode === 'asc') {
          return (a.organization.toLowerCase() < b.organization.toLowerCase()) ? -1 : 1;
        } else {
          return (a.organization.toLowerCase() >= b.organization.toLowerCase()) ? -1 : 1;
        }
      }

      if (licensesOrderBy.field === 'name') {
        if (licensesOrderBy.mode === 'asc') {
          return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1;
        } else {
          return (a.name.toLowerCase() >= b.name.toLowerCase()) ? -1 : 1;
        }
      }

      if (licensesOrderBy.field === 'maxUsers') {
        if (licensesOrderBy.mode === 'asc') {
          if (!a.maxUsers) return -1;
          if (!b.maxUsers) return 1;
          return (a.maxUsers < b.maxUsers) ? -1 : 1;
        } else {
          if (!a.maxUsers) return 1;
          if (!b.maxUsers) return -1;
          return (a.maxUsers >= b.maxUsers) ? -1 : 1;
        }
      }

      if (licensesOrderBy.field === 'expiration') {
        if (licensesOrderBy.mode === 'asc') {
          if (!a.daysUntilExpiration) return -1;
          if (!b.daysUntilExpiration) return 1;
          return (a.daysUntilExpiration < b.daysUntilExpiration) ? -1 : 1;
        } else {
          if (!a.daysUntilExpiration) return 1;
          if (!b.daysUntilExpiration) return -1;
          return (a.daysUntilExpiration >= b.daysUntilExpiration) ? -1 : 1;
        }
      }

      return 1;
    });

    return licenses;
  };

  const setLicensesOrder = (orderBy) => {
    setLicensesOrderBy({
      field: orderBy,
      mode: (licensesOrderBy.field === orderBy && licensesOrderBy.mode === 'asc') ? 'desc' : 'asc'
    });
  };

  useEffect(() => {
    if (!isMounted.current) return;

    getLicensesType();
    getLicensesData();

    return (() => {
      hideToastMessage();
    })
  }, []);

  useEffect(() => {
    filterLicenses();
  }, [filterName, filterLicense, licensesOrderBy.field, licensesOrderBy.mode]);

  return (
    <Layout>
      <CustomModal
        id="create-organization-modal"
        title="Create new Organization"
        shiftedRight={true}
        open={addOrganizationModalOpened}
        handleClose={() => setAddOrganizationModalOpened(false)}
      >
        <OrganizationModel
          mode="add"
          licenses={licenseTypes}
          handleCloseModal={() => setAddOrganizationModalOpened(false)}
          refreshOrganizationList={getLicensesData}
        />
      </CustomModal>

      <CustomModal
        id="view-organization-modal"
        title="View Organization details"
        shiftedRight={true}
        open={viewOrganizationModalOpened}
        handleClose={() => setViewOrganizationModalOpened(false)}
      >
        <OrganizationModel
          mode="view"
          data={organizationData}
          licenses={licenseTypes}
          handleCloseModal={() => setViewOrganizationModalOpened(false)}
          refreshOrganizationList={getLicensesData}
        />
      </CustomModal>

      <CustomModal
        id="edit-organization-modal"
        title="Edit Organization details"
        shiftedRight={true}
        open={editOrganizationModalOpened}
        handleClose={() => setEditOrganizationModalOpened(false)}
      >
        <OrganizationModel
          mode="edit"
          data={organizationData}
          licenses={licenseTypes}
          handleCloseModal={() => setEditOrganizationModalOpened(false)}
          refreshOrganizationList={getLicensesData}
        />
      </CustomModal>

      <PromptModal
        id="delete-organization-modal"
        title="Delete Organization?"
        text={`Do you confirm delete the Organization '${organizationData?.organization}'?`}
        open={deleteOrganizationModalOpened}
        handleConfirm={() => setDeleteOrganizationModalOpened(false)}
        handleClose={() => setDeleteOrganizationModalOpened(false)}
      />

      <CustomModal
        id="create-license-modal"
        title="Create new License"
        shiftedRight={true}
        open={licenseTypeModalOpened}
        handleClose={handleCloseLicenseModal}
      >
        <LicenseModel handleCloseModal={handleCloseLicenseModal} />
      </CustomModal>

      <div className="main-section">
        <div className="main-table">
          <div className="table-title no-margin">
            <div className="title">Licenses List</div>
            <div className="actions">
              <Button
                id="button-new-organization"
                variant="outlined"
                className="button-outline"
                onClick={() => setAddOrganizationModalOpened(true)}
              >
                NEW LICENSE FORM
              </Button>
            </div>
          </div>

          {!loadingData && licenses.length > 0 &&
            <div className="table-filter">
              <div className="table-filter-column" style={{ width: '74%', paddingRight: '1%' }}>
                <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                  <InputLabel htmlFor="filter-name" style={{ fontSize: '0.8em' }}>Filter by organization</InputLabel>
                  <OutlinedInput
                    id="filter-name"
                    value={filterName}
                    label="Filter by organization"
                    onChange={e => setFilterName(e.target.value)}
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    style={{ fontSize: '0.8em' }}
                    endAdornment={
                      filterName &&
                      <InputAdornment position="end">
                        {filterName &&
                          <Tooltip title="Clear">
                            <IconButton
                              aria-label="clear"
                              onClick={() => setFilterName('')}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="table-filter-column" style={{ width: '25%' }}>
                <FormControl sx={{ my: 1, width: '100%' }} variant="outlined" fullWidth>
                  <InputLabel htmlFor="filter-status" style={{ fontSize: '0.8em' }}>Filter by License</InputLabel>
                  <Select
                    id="filter-license"
                    value={filterLicense}
                    label="Filter by license"
                    style={{ fontSize: '0.8em' }}
                    onChange={e => setFilterLicense(e.target.value)}
                  >
                    {licenseToFilter.length <= 0 &&
                      <MenuItem value="">No license types found!</MenuItem>
                    }
                    {licenseToFilter.length > 0 &&
                      <MenuItem value="">No filter</MenuItem>
                    }
                    {licenseToFilter.map((licenseType, index) => {
                      return <MenuItem key={index} value={licenseType}>{licenseType}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          }

          <div className="table-content">
            {loadingData &&
              <div className="table-loading">
                Loading licenses data...
              </div>
            }

            {!loadingData &&
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="table-heading">
                      <TableCell className="fs-11 show-pointer no-select" onClick={() => setLicensesOrder('organization')}>ORGANIZATION</TableCell>
                      <TableCell className="fs-11 show-pointer no-select" onClick={() => setLicensesOrder('id')}>LICENSE ID</TableCell>
                      <TableCell className="fs-11 show-pointer no-select" onClick={() => setLicensesOrder('expiration')}>EXPIRATION</TableCell>
                      <TableCell className="fs-11 show-pointer no-select" onClick={() => setLicensesOrder('maxUsers')}>MAX USERS</TableCell>
                      <TableCell className="fs-11 no-select">ACTIONS</TableCell>
                    </TableRow>
                  </TableHead>

                  {licenses.length <= 0 &&
                    <TableBody>
                      <TableRow sx={{ border: 0 }}>
                        <TableCell colSpan={5} className="table-loading-no-flex">Error loading licenses! Please try again later.</TableCell>
                      </TableRow>
                    </TableBody>
                  }

                  {licenses.length > 0 &&
                    <TableBody>
                      {filteredLicenses.map((license) => (
                        <TableRow
                          key={license.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell className="table-cell-one">{license.organization}</TableCell>
                          <TableCell className="table-cell">{license.id}</TableCell>
                          <TableCell className="table-cell align-center">
                            {license.daysUntilExpiration > 30 && <span>{license.daysUntilExpiration} days</span>}
                            {license.daysUntilExpiration > 1 && license.daysUntilExpiration <= 30 && <span className="pill pill-yellow">1 day</span>}
                            {license.daysUntilExpiration === 1 && <span className="pill pill-yellow">1 day</span>}
                            {license.daysUntilExpiration <= 0 && <span className="pill pill-red">EXPIRED</span>}
                          </TableCell>
                          <TableCell className="table-cell align-center">{license.maxUsers}</TableCell>
                          <TableCell className="table-actions align-center">
                            <Tooltip title="View details">
                              <IconButton
                                aria-label="View details"
                                onClick={() => {
                                  setOrganizationData(license);
                                  setViewOrganizationModalOpened(true);
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit details">
                              <IconButton
                                aria-label="Edit details"
                                onClick={() => {
                                  setOrganizationData(license);
                                  setEditOrganizationModalOpened(true);
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="View details">
                              <IconButton
                                aria-label="View details"
                                onClick={() => {
                                  setOrganizationData(license);
                                  setDeleteOrganizationModalOpened(true);
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  }
                </Table>
              </TableContainer>
            }
          </div>
        </div>
      </div>
    </Layout >
  );
}
