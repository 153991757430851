import React, { useEffect, useState, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { LinearProgressWithValue } from '../../helpers/progress-helpers';

import {
  Grid, Button, FormControl, OutlinedInput, InputLabel, Select, MenuItem, InputAdornment, IconButton, Tooltip, 
} from '@mui/material';

/**************************************
** Component styles
***************************************/
import './file-upload.scss';
import { useIsMounted } from '../../helpers/global-helpers';

interface FileUploadinterface {
  title?: string;
  maximumSize?: number;
  supportMultipleFiles: boolean;
  supportFileTypes?: string[];
  uploadDocumentCallback: any;
  uploadingProgress: number;
  finishUpload: boolean;
}

export const FileUpload = (props: FileUploadinterface) => {

  const isMounted = useIsMounted();
  const title = (props.title || 'Drag \'n\' drop some files here, or click to select files');
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState('');
  const [uploadingMode, setUploadingMode] = useState(false);

  const handleOnDrop = (acceptedFiles) => {
    if (!acceptedFiles || acceptedFiles.length <= 0){
      setError('File selected not readable. Please try again!');
      return;
    }

    if (!props.supportMultipleFiles && acceptedFiles.length > 1){
      setError('Please select only 1 file at a time!');
      return;
    }

    // Validate file types
    if (props.supportFileTypes && props.supportFileTypes.indexOf(acceptedFiles[0].type.toLowerCase()) <= -1){
      setError('File type not accepted!');
      return;
    }

    setFileName(acceptedFiles[0]);
  }

  const getMaximumSizeFormatted = (size) => {
    let totalSize = "";
    if (size > (1024 * 1024)){
      totalSize = Math.round(size / (1024 * 1024)).toString() + "Mb.";
    }else if (size > 1024){
      totalSize = Math.round(size / 1024).toString() + "Kb.";
    }else{
      totalSize = Math.round(size).toString() + "bytes";
    }

    return totalSize;
  };

  useEffect(() => {
    if (!isMounted.current) return;
    if (props.finishUpload){
      setFileName(null);
      setUploadingMode(false);
    }

  }, [props.finishUpload]);

  return (
    <div className="file-upload-main">
      <div className="file-upload-container">
        <div className="file-upload-area">
          <Dropzone
            onDrop={handleOnDrop}
            maxFiles={props.supportMultipleFiles ? 0 : 1}
            maxSize={props?.maximumSize || 16000000}
            noKeyboard={true}
            disabled={uploadingMode}
          >
            {({getRootProps, getInputProps}) => (
              <section className="file-upload-area-section">
                <div {...getRootProps()} className="file-upload-area-elements">
                  <input {...getInputProps()} />
                  <div className="file-upload-area-texts">
                    {error && 
                      <p className="file-upload-error">{error}</p>
                    }
                    {!fileName && 
                      <p className="file-upload-message">{title}</p>
                    }
                    {fileName && !uploadingMode &&
                      <p className="file-upload-message">Confirm uploading file?</p>
                    }
                    {fileName && uploadingMode &&
                      <p className="file-upload-message">Uploading...</p>
                    }
                    {fileName &&
                      <p className="file-upload-filename">{fileName?.name || ''}</p>
                    }
                  </div>
                  {fileName && !uploadingMode &&
                    <div className="file-upload-buttons">
                      <Button
                        id="file-upload-cancel"
                        variant="contained"
                        className="button-login fs-11"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setFileName(null);
                        }}
                      >
                        CANCEL
                      </Button>
                      <Button
                        id="file-upload-confirm"
                        variant="contained"
                        className="button-login fs-11"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setUploadingMode(true);
                          props.uploadDocumentCallback(fileName);
                        }}
                      >
                        UPLOAD
                      </Button>
                    </div>
                  }
                  {fileName && uploadingMode && 
                    <LinearProgressWithValue value={props.uploadingProgress} />
                  }
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <div className="file-upload-extras">
          {props.maximumSize && 
            <span className="max-upload-span">Maximum upload file size: {getMaximumSizeFormatted(props.maximumSize)}</span>
          }
        </div>
      </div>
    </div>
  );
}
