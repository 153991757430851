import React, { useEffect, useState } from 'react';
import { addNewUsers } from '../../api/LCMApi';
import {
  FormControl,  OutlinedInput, Button, InputAdornment, Grid, Tooltip, Alert, 
} from '@mui/material';
import {
  useIsMounted, showToastMessage, hideToastMessage, 
  validateEmail, validatePhoneNumber,
} from '../../helpers/global-helpers';
import './UsersAddPage.scss';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface UsersAddPageInterface {
  userData: {
    email: string;
    phoneNumber: string;
  },
  validateEmail: any;
  refreshUserList: any;
  onClose: any;
}

const UsersAddPage = (props: UsersAddPageInterface) => {
  const isMounted = useIsMounted();

  const [email, setEmail] = useState(props.userData.email);
  const [phoneNumber, setPhoneNumber] = useState(props.userData.phoneNumber);

  const [formError, setFormError] = useState('');
  const [formInProgress, setFormInProgress] = useState(false);

  const closeAction = () => {
    props.onClose();
  }

  async function confirmAddUser() {
    if (!email){
      setFormError('Complete email!');
      return;
    }

    if (!validateEmail(email)){
      setFormError('Email is not valid!');
      return;
    }

    const emailAlreadyExist = props.validateEmail(email);
    if (emailAlreadyExist){
      setFormError('Email is already registered as a user!');
      return;
    }

    if (!validatePhoneNumber(phoneNumber)){
      setFormError('Phone number is not valid!');
      return;
    }

    // Add user
    setFormInProgress(true);
    showToastMessage('Adding user...', 'loading', { id: 'add-user' });
    const newUser = {
      email: email,
      phoneNumber: phoneNumber,

    };
    const userAdded = await addNewUsers([newUser]);

    if (!isMounted.current) return;

    if (!userAdded.status){
      setFormInProgress(false);
      showToastMessage('Error adding user...', 'error', { id: 'add-user' });
      return;
    }
  
    setFormInProgress(false);
    showToastMessage('User added succesfully...', 'success', { id: 'add-user' });
    props.refreshUserList();
    closeAction();
}

  useEffect(() => {
    if (!isMounted.current) return;
    if (!props.userData) return;

    setEmail(props.userData.email);
    setPhoneNumber(props.userData.phoneNumber);

    return(() => {
      hideToastMessage();
    })
  }, [props.userData]);

  return (
    <div className="main-form">
      <div className="form-title">User Information</div>
      <div className="form-body">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="form-label">Email</div>
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
              <OutlinedInput
                id="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                disabled={formInProgress}
                fullWidth
                inputProps={{ maxLength: 100 }}
                endAdornment={
                  (email || formError) &&
                  <InputAdornment position="end">
                      {validateEmail(email) ? 
                        <Tooltip title="Validated!">
                          <CheckCircleIcon className="green" />
                        </Tooltip>
                      :
                        <Tooltip title="Not a valid email!">
                          <ErrorIcon className="red" />
                        </Tooltip>
                      }
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div className="form-label">Phone Number</div>
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
              <OutlinedInput
                id="phoneNumber"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                disabled={formInProgress}
                fullWidth
                inputProps={{ maxLength: 100 }}
                endAdornment={
                  (phoneNumber || formError) &&
                  <InputAdornment position="end">
                      {validatePhoneNumber(phoneNumber) ? 
                        <Tooltip title="Validated!">
                          <CheckCircleIcon className="green" />
                        </Tooltip>
                      :
                        <Tooltip title="Not a valid phone number!">
                          <ErrorIcon className="red" />
                        </Tooltip>
                      }
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={8} className="form-error form-alert-error">
            {formError && 
              <Alert severity="error">{formError}</Alert>
            }
          </Grid>
          <Grid item xs={4}>
            <div className="form-actions">
              <Button
                id="add-user-confirm"
                className="button-outline form-button"
                variant="outlined"
                style={{
                  backgroundColor: (!formInProgress ? "#ffffff" : "#bbbbbb"),
                  color: (!formInProgress ? "#999999" : "#999999"),
                }}
                disabled={formInProgress}
                onClick={() => closeAction()}
              >
                CANCEL
              </Button>
              <Button
                id="add-user-confirm"
                className="button form-button"
                variant="contained"
                style={{
                  backgroundColor: (!formInProgress ? "#077764" : "#bbbbbb"),
                  color: (!formInProgress ? "#ffffff" : "#999999"),
                }}
                disabled={formInProgress}
                onClick={() => confirmAddUser()}
              >
                CONFIRM
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default UsersAddPage;
