import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, MenuItem, Paper,
  Tooltip, IconButton, responsiveFontSizes,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import Radio from "@mui/material/Radio";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { FormControl } from "@mui/material";
import Select from "@mui/material/Select";
import { createTemplateData, getLicenseTemplate, getLicenseById, updateTemplateData, DeleteLicense } from "../api/LCMApi";
import {
  renewalOptions,
  contractLengthOptions,
  paymentTermOptions,
  maxNoOfUsersOptions,
  maxNoOfProgramsOptions
} from "../constants/LicenseTemplateOptions";
import "./TemplatesPage.scss";
import { useIsMounted, beautifyConstrain } from "../helpers/global-helpers";

import PromptModal from '../components/custom-modal/PromptModal';

function createData(
  company: string,
  license_type: string,
  expiration: string,
  numbers_of_users: string,
  data_storage: number,
  cpu_usage: number
) {
  return {
    company,
    license_type,
    expiration,
    numbers_of_users,
    data_storage,
    cpu_usage,
  };
}

const tableHeaderOptions = [{
  title: "select",
  key: '',
}, {
  title: "Type of License",
  key: 'name',
}, {
  title: "Renewal",
  key: 'renewalOption',
}, {
  title: "Contract Length",
  key: 'contractLength',
}, {
  title: "Payment Terms",
  key: 'paymentTerm',
}, {
  title: "Max. Number of Users",
  key: 'maxUsers',
}, {
  title: "Max. Number of programs",
  key: 'maxPrograms',
}, {
  title: "Actions",
  key: '',
},
];


function TemplatesPage() {
  const isMounted = useIsMounted();

  const [loadingData, setLoadingData] = useState(true);
  const [templatesOrderBy, setTemplatesOrderBy] = useState({ field: 'name', mode: 'asc' });

  const [edit, setedit] = useState("");

  // const { isEditMode } = row;
  const [renewal, setRenewal] = useState("");
  const [contractLength, setContractLength] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [maxNoOfUsers, setMaxNoOfUsers] = useState("");
  const [maxNoOfPrograms, setMaxNoOfPrograms] = useState("");
  const [templateData, setTemplateData] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [settings, setSettings] = useState("");
  const [showSettings, setShowSettings] = useState(false);
  const [riskFactorValue, setRiskFactorValue] = useState(0);
  const [sizeOfDataset, setSizeOfDataset] = useState(0);
  const [noOfTiers, setNoOfTiers] = useState(0);
  const [newRow, setNewRow] = useState(true);
  const [open, setOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState('');
  const [firstRow,setFirstRow] = useState(false)
 
  const getConvertedData = (value) => {
    const splitted = value.split("_");
    if (splitted.length <= 1) return value;

    if (splitted[1] === '1') splitted[0] = splitted[0].substring(0, splitted[0].length - 1);
    return splitted[1] + " " + splitted[0]?.toLowerCase();
  };

  const getMaxUsers = (item) => {
    const constraint = item.constraints.find(
      (item) => item.name === "MAX_USERS"
    );
    return constraint ? constraint.value : null;
  };

  const getMaxPrograms = (item) => {
    const constraint = item.constraints.find(
      (item) => item.name === "MAX_PROGRAMS"
    );
    return constraint ? constraint.value : null;
  };

  const setTemplatesOrder = (orderBy) => {
    setTemplatesOrderBy({
      field: orderBy,
      mode: (templatesOrderBy.field === orderBy && templatesOrderBy.mode === 'asc') ? 'desc' : 'asc'
    });
    return;
  };

  const applyOrderBy = (data?) => {
    const templates = (data) ? data : templateData.slice();

    if (templatesOrderBy.field === 'maxUsers' || templatesOrderBy.field === 'maxPrograms') {
      templates.sort((a, b) => {
        if (templatesOrderBy.mode === 'asc') {
          if (!a[templatesOrderBy.field]) return -1;
          if (!a[templatesOrderBy.field]) return 1;
          return (a[templatesOrderBy.field] < b[templatesOrderBy.field]) ? -1 : 1;
        } else {
          if (!a[templatesOrderBy.field]) return 1;
          if (!a[templatesOrderBy.field]) return -1;
          return (a[templatesOrderBy.field] >= b[templatesOrderBy.field]) ? -1 : 1;
        }
      });
    } else {
      templates.sort((a, b) => {
        if (templatesOrderBy.mode === 'asc') {
          if (!a[templatesOrderBy.field]) return -1;
          if (!a[templatesOrderBy.field]) return 1;
          return (a[templatesOrderBy.field].toLowerCase() < b[templatesOrderBy.field].toLowerCase()) ? -1 : 1;
        } else {
          if (!a[templatesOrderBy.field]) return 1;
          if (!a[templatesOrderBy.field]) return -1;
          return (a[templatesOrderBy.field].toLowerCase() >= b[templatesOrderBy.field].toLowerCase()) ? -1 : 1;
        }
      });
    }

    setTemplateData(templates);
  };

  useEffect(() => {
    if (!isMounted.current) return;

    const getData = async () => {
      const response = await getLicenseTemplate();

      if (!response.status || !response.data || response.data.length <= 0) {
        setTemplateData([]);
        setLoadingData(false);
      }

      // Get constrains
      response.data.forEach((license) => {
        license.maxUsers = null;
        license.maxPrograms = null;

        // find number of programs and users
        license.constraints && license.constraints.forEach((constrain) => {
          switch (constrain.name) {
            case 'MAX_PROGRAMS':
              license.maxPrograms = constrain.value;
              return;

            case 'MAX_USERS':
              license.maxUsers = constrain.value;
              return;
          }
        });
      });
      applyOrderBy(response.data);
      setLoadingData(false);
    };

    setLoadingData(true);
    getData();
  }, []);

  useEffect(() => {
    if (!isMounted.current) return;
    if (templateData.length <= 0) return;

    applyOrderBy();
  }, [templatesOrderBy.field, templatesOrderBy.mode]);
  const firstRowBorder = ()=>{
    let selectedTableRow = document.getElementById("0");
    selectedTableRow?.classList?.add("border");
  }
  useEffect(()=>{
    firstRowBorder()
  },[firstRow === true])
  const NewLicenseHandler = () => {
    setFirstRow(true)
    if(selectedValue){
      let oldTableRow = document.getElementById(selectedValue)
      oldTableRow?.classList?.remove('border')
      firstRowBorder()
    }
    firstRowBorder()
    setNewRow(true)
    setSelectedValue("0")
    setedit("0")
    const NewTemplateData = [...templateData]
    if (newRow) {
      NewTemplateData.unshift({ name: "DefaultLicense", renewalOption: "NOT_RENEWABLE", contractLength: "DAYS_60", paymentTerm: "MONTHLY", constraints: [{ name: "TABLET", value: true }, { name: "MAX_PROGRAMS", value: 55 }, { name: "MAX_USERS", value: 70 }] })
      setTemplateData(NewTemplateData)
      setedit("0")
    }
    setNewRow(false)
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getOrgdata = async (id) => {
    const response = await getLicenseById(id);
    if (response && response.data) {
      const tempData = templateData.filter(
        (element) => {
          return element.id === id;
        }
      );
      setShowSettings(false)
      tempData[0].renewalOption = renewal;
      tempData[0].contractLength = contractLength;
      tempData[0].paymentTerm = paymentTerms;
      tempData[0].organization = response.data['organization'];
      tempData[0].notes = response.data['notes'];
      tempData[0].licenseState = response.data['licenseState'] || "Active";
      tempData[0].constraints.forEach((element) => {
        if (element.name === "MAX_PROGRAMS") {
          element.value = maxNoOfPrograms;
        }
        if (element.name === "MAX_USERS") {
          element.value = maxNoOfUsers;
        }
      });
      if (newRow) {
        updateTemplateData([...organizationData, tempData[0]]);
        let oldTableRow = document.getElementById(selectedValue);
        oldTableRow?.classList?.remove("border");
        // setSelectedValue("")
      }
      else {
        // createTemplateData([...organizationData, tempData[0]]);
        createTemplateData(tempData[0]);
        let oldTableRow = document.getElementById("0");
        oldTableRow?.classList?.remove("border");
      }
      // setSelectedValue("")
      setNewRow(true)
    }
  }
  return (
    <Layout>
      <div className="main-section">
        <div className="main-table">
          <div className="table-title no-margin">
            <div className="title">License Templates</div>
            <div className="actions">
              <Button
                id="button-new-license"
                variant="outlined"
                className="button-outline"
                onClick={NewLicenseHandler}
              >
                ADD
              </Button>
            </div>
          </div>

          {!loadingData &&
            <div className="license-table-title fs-15">
              Select a license type from the list:
            </div>
          }

          <div className="table-content">
            {loadingData &&
              <div className="table-loading">
                Loading templates data...
              </div>
            }

            {!loadingData &&
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="table-heading">
                      {tableHeaderOptions?.map((item, index) => {
                        return (
                          <TableCell
                            key={index + 1}
                            className={`fs-11 ${(item.key) ? 'show-pointer' : ''} no-select`}
                            onClick={() => {
                              if (item.key) setTemplatesOrder(item.key)
                            }}>
                            {item.title.toLocaleUpperCase()}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {templateData?.map((item, index) => (
                      <>
                        {/* <TableRow key={item.id} id={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}> */}
                        <TableRow key={item.id} id={index.toString()} className="tableRow" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>
                            <Radio
                              name="selected_row"
                              value={index}
                              // value={item.id}
                              checked={
                                selectedValue === index.toString() || edit === index.toString() //edit ===item.id
                              }
                              onChange={(e) => {
                                let selectedTableRow = document.getElementById(
                                  e.target.value
                                );
                                selectedTableRow.classList.add("border");
                                let oldTableRow =
                                  document.getElementById(selectedValue);
                                oldTableRow?.classList?.remove("border");
                                setSelectedValue(e.target.value);
                                setRenewal(item.renewalOption);
                                setContractLength(item.contractLength);
                                setPaymentTerms(item.paymentTerm);
                                setMaxNoOfUsers(item.maxNoOfUsers);
                                setMaxNoOfPrograms(item.maxNoOfPrograms);
                                setShowSettings(false)
                                setedit(index.toString());
                              }}
                            />
                          </TableCell>
                          <TableCell className="first-cell">{item.name}</TableCell>
                          <TableCell className="all-cell">
                            {edit === index.toString() ? (
                              <div>
                                <FormControl style={{ width: "100%" }}>
                                  <Select
                                    className="Allselect"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={renewal}
                                    onChange={(e) => {
                                      setRenewal(e.target.value);
                                    }}
                                    defaultValue={item.renewal}
                                  >
                                    {renewalOptions.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>{option.displayName}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            ) : (
                              beautifyConstrain(item.renewalOption)
                            )}
                          </TableCell>
                          <TableCell className="all-cell">
                            {edit === index.toString() ? (
                              <div>
                                <FormControl style={{ width: "100%" }}>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={contractLength}
                                    className="Allselect"
                                    onChange={(e) => {
                                      setContractLength(e.target.value);
                                    }}
                                    defaultValue={getConvertedData(
                                      item.contractLength
                                    )}
                                  >
                                    {contractLengthOptions.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>{option.displayName}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            ) : (
                              getConvertedData(item.contractLength)
                            )}
                          </TableCell>
                          <TableCell className="all-cell">
                            {edit === index.toString() ? (
                              <div>
                                <FormControl style={{ width: "100%" }}>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={paymentTerms}
                                    className="Allselect"
                                    onChange={(e) => {
                                      setPaymentTerms(e.target.value);
                                    }}
                                    defaultValue={item.paymentTerm}
                                  >
                                    {paymentTermOptions.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>{option.displayName}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            ) : (
                              item.paymentTerm
                            )}
                          </TableCell>
                          <TableCell className="all-cell">
                            {edit === index.toString() ? (
                              <div>
                                <FormControl style={{ width: "100%" }}>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={maxNoOfUsers}
                                    className="Allselect"
                                    onChange={(e) => {
                                      setMaxNoOfUsers(e.target.value);
                                    }}
                                    defaultValue={getMaxUsers(item)}
                                  >
                                    {maxNoOfUsersOptions.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>{option.displayName}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            ) : (
                              getMaxUsers(item)
                            )}
                          </TableCell>
                          <TableCell className="all-cell">
                            {/* {edit === item.id ? ( */}
                            {edit === index.toString() ? (
                              <div>
                                <FormControl style={{ width: "100%" }}>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={maxNoOfPrograms}
                                    className="Allselect"
                                    onChange={(e) => {
                                      setMaxNoOfPrograms(e.target.value);
                                    }}
                                    defaultValue={getMaxPrograms(item)}
                                  >
                                    {maxNoOfProgramsOptions.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>{option.displayName}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            ) : (
                              getMaxPrograms(item)
                            )}
                          </TableCell>
                          <TableCell className="all-cell table-actions">
                            {/* {edit !== item.id ? ( */}
                            {edit !== index.toString() ? (
                              <>
                                <Tooltip title="Edit">
                                  <IconButton
                                    onClick={(e) => {
                                      let radioSelect = document.getElementById(
                                        item.id
                                      );
                                      let selectedTableRow = document.getElementById(
                                        index.toString()
                                      );
                                      selectedTableRow.classList.add("border");
                                      // setOldTableRow(index.toString());
                                      let oldTableRow =
                                        document.getElementById(selectedValue);
                                      oldTableRow?.classList?.remove("border");
                                      setSelectedValue(index.toString());
                                      // setedit(item.id);
                                      setedit(index.toString());
                                      setShowSettings(false);
                                      setRenewal(item.renewalOption);
                                      setContractLength(item.contractLength);
                                      setPaymentTerms(item.paymentTerm);
                                      setMaxNoOfUsers(
                                        getMaxUsers(item) ? getMaxUsers(item) : " "
                                      );
                                      setMaxNoOfPrograms(
                                        getMaxPrograms(item)
                                          ? getMaxPrograms(item)
                                          : " "
                                      );
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton
                                    onClick={(e) => {
                                      setOpen(true)
                                      setDeleteIndex(item.id)
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <div className="save-button">
                                <Tooltip title="View Other Factors">
                                  <IconButton
                                    onClick={() => {
                                      setShowSettings(showSettings => !showSettings);
                                      setSettings(item.id);
                                    }}
                                  >
                                    <SettingsIcon />
                                  </IconButton>
                                </Tooltip>
                                {" "}
                                <Tooltip title="Save">
                                  <Button
                                    variant="text"
                                    onClick={() => {
                                      if(selectedValue === "0"){
                                        setNewRow(true)
                                      }
                                      else{
                                        setNewRow(false)
                                      }
                                      setedit(""); 
                                      if(newRow){
                                        getOrgdata(item.id)
                                        let oldTableRow = document.getElementById(selectedValue);
                                        oldTableRow?.classList?.remove("border");
                                        setSelectedValue("")
                                      } else {
                                        const tempData = templateData.filter(
                                          (element) => {
                                            return element.id === item.id;
                                          }
                                        );
                                          setShowSettings(false)
                                        tempData[0].renewalOption = renewal;
                                        tempData[0]["licenseState"] = null;
                                        tempData[0].contractLength = contractLength;
                                        tempData[0].paymentTerm = paymentTerms;
                                        tempData[0]["organization"] = ("Default" + Math.floor(Math.random() * 100000));
                                        tempData[0]["startDate"] = "2022-02-23";
                                        tempData[0]["expirationDate"] = "2022-03-21";
                                        tempData[0]["daysUntilExpiration"] =  40;
                                        tempData[0].constraints.forEach((element) =>{
                                          if (element.name === "TABLET") {
                                            element["constraintType"] = "boolean"
                                            element.value = true;
                                          } 
                                          if (element.name === "MAX_PROGRAMS") {
                                            element["constraintType"] = (typeof maxNoOfPrograms === "number")
                                            element.value = maxNoOfPrograms;
                                          }
                                          if (element.name === "MAX_USERS") {
                                            element["constraintType"] =  (typeof maxNoOfUsers === "number")
                                            element.value = maxNoOfUsers;
                                          }
                                        });
                                         createTemplateData(tempData[0]);
                                          // createTemplateData(tempData[0]);
                                        let oldTableRow = document.getElementById("0");
                                        oldTableRow?.classList?.remove("border");
                                        setSelectedValue("")
                                      }
                                      let oldTableRow = document.getElementById(selectedValue);
                                        oldTableRow?.classList?.remove("border");
                                      setSelectedValue("")
                                      setNewRow(true)
                                    }}
                                  >
                                    SAVE
                                  </Button>
                                </Tooltip>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                        {settings === item.id && showSettings ? (
                          <TableRow className="border">
                            <TableCell></TableCell>
                            <TableCell className="first-cell">OTHER FACTORS</TableCell>
                            <TableCell className="all-cell">
                              <div className="risk">RISK FACTORS</div>
                              <div className="risk">SIZE OF DATASET</div>
                              <div className="risk">NUMBER OF TIERS</div>
                            </TableCell>
                            <TableCell className="all-cell" >
                              <div className="con-button"><button onClick={() => setRiskFactorValue((riskFactorValue) => riskFactorValue - 1)} ><ArrowLeftIcon /></button>
                                <input type="number" value={riskFactorValue} onChange={(e) => setRiskFactorValue(Number(e.target.value))} />
                                <button onClick={() => setRiskFactorValue((riskFactorValue) => riskFactorValue + 1)} className="right-button"><ArrowRightIcon /></button></div>
                              <div className="con-button"><button onClick={() => setSizeOfDataset((sizeOfDataset) => sizeOfDataset - 1)}><ArrowLeftIcon /></button>
                                <input type="number" value={sizeOfDataset} onChange={(e) => setSizeOfDataset(Number(e.target.value))} />
                                <button onClick={() => setSizeOfDataset((sizeOfDataset) => sizeOfDataset + 1)} className="right-button"><ArrowRightIcon /></button></div>
                              <div className="con-button"><button onClick={() => setNoOfTiers((noOfTiers) => noOfTiers - 1)} ><ArrowLeftIcon /></button>
                                <input type="number" value={noOfTiers} onChange={(e) => setNoOfTiers(Number(e.target.value))} />
                                <button onClick={() => setNoOfTiers((noOfTiers) => noOfTiers + 1)} className="right-button"><ArrowRightIcon /></button></div>
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
            <PromptModal
              id="delete-license"
              title="Delete License?"
              text={`Do you Want to Delete License?`}
              open={open}
              handleConfirm={() => {
                DeleteLicense(deleteIndex)
                getLicenseTemplate()
                handleClose()
              }}
              handleClose={() => handleClose()}
            />
          </div>
        </div>
      </div >
    </Layout >
  );
}

export default TemplatesPage;