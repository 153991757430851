import React, { useState, useEffect } from 'react';
import { Backdrop, Modal, Fade } from '@mui/material';
import { useIsMounted } from '../../helpers/global-helpers';
import CloseIcon from '@mui/icons-material/Close';
import './CustomModal.scss';

interface CustomModalInterface {
  id: string;
  title: string;
  open: boolean;
  shiftedRight?: boolean;
  handleClose: any;
  children?: any;
}

const CustomModal = (props: CustomModalInterface): JSX.Element => {
  const isMounted = useIsMounted();
  const [open, setOpen] = useState(props.open);
  const shiftedRight = props?.shiftedRight ? props?.shiftedRight : false;

  const closeModal = () => {
    setOpen(false);
    props.handleClose();
  }
  useEffect(() => {
    if (!isMounted.current) return;

    if (props.open !== open) setOpen(props.open);
  }, [props.open]);

  return (
    <Modal
      id={props.id}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="custom-modal"
      open={open}
      onClose={() => {}}
      closeAfterTransition
      disableEscapeKeyDown={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={`internal ${shiftedRight ? 'internal-shifted-left' : ''}`}>
          <div className="modal-header">
            <div className="title">{props.title}</div>
            <div className="close">
              <div
                id="modal-close-button"
                className="modal-close-button"
                onClick={() => closeModal()}
              >
                <CloseIcon sx={{ color: '#EEEEEE' }} />
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-body-internal">
              {props.children}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default CustomModal;