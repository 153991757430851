import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import Auth from '@aws-amplify/auth';
import { AppContext } from '../context/AppContext';
import { FormControl, Checkbox, InputLabel, OutlinedInput, InputAdornment, IconButton, Button } from '@mui/material';
import { useIsMounted, saveUserDataToSessionStorage, validateAnyUserRoles } from '../helpers/global-helpers';

/**************************************
** Component styles
***************************************/
import { EmailOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import './LoginPage.scss';

export interface LoginProps {
  email?: string;
  match?: any;
}

export function LoginPage(props: LoginProps): JSX.Element {
  const isMounted = useIsMounted();

  const emailQS = props?.match?.params?.id || props?.email;
  const [email, setEmail] = useState((emailQS) ? emailQS : '');
  const [password, setPassword] = useState('');
  const [passVisibility, setPassVisibility] = useState(false);
  const [remember, setRemember] = useState(false);
  const [formInProgress, setFormInProgress] = useState(false);
  const [loginError, setLoginError] = useState('');

  const history = useHistory();
  const { contextData, setContextData } = useContext(AppContext);

  // Read Context Data values
  const { currentURI } = contextData;

  const isDisabled: boolean = !email || !password || formInProgress ? true : false;

  function redirectTo(page) {
    history.push(page);
  }

  function redirectToDashboard(){
    history.push(currentURI ? currentURI : '/dashboard');
  }

  function handleShowPassword() {
    !formInProgress && setPassVisibility(!passVisibility);
  }

  function handleMouseDownPassword(event){
    event.preventDefault();
  }

  function loginSuccess(): void {
    // Redirect to saved URI
    redirectToDashboard();
  }

  async function handleSignIn() {
    setLoginError('Validating access...');
    setFormInProgress(true);

    let user;
    try {
      user = await Auth.signIn(email, password);
    } catch (e) {
      if (!e || !e.code) {
        setLoginError('Incorrect username or password');
        setFormInProgress(false);
        return;
      }

      // Check current error
      switch (e.code) {
        case 'NotAuthorizedException':
          (e.message.toLowerCase() === 'user is disabled.') ?
            setLoginError('User is currently disabled. Contact the administrador.')
            :
            setLoginError('Incorrect username or password');
          break;

        default:
          setLoginError('Incorrect username or password');
          break;
      }

      setFormInProgress(false);
      return;
    }

    if (!isMounted.current) return;

    // Check token
    let validAWSData = user && user.signInUserSession;
    validAWSData = validAWSData && user.signInUserSession.accessToken;
    validAWSData = validAWSData && user.signInUserSession.accessToken.payload;
    validAWSData = validAWSData && user.signInUserSession.accessToken.jwtToken;
    if (validAWSData) {
      // Check Roles to access LCM
      const validRoles = ["licenseManager"];
      const validRole = validateAnyUserRoles(validRoles, JSON.stringify(user.signInUserSession.accessToken.payload["cognito:groups"]));
      if (!validRole){
        setLoginError('Your user has no access to LCM');
        await Auth.signOut();

        if (!isMounted.current) return;
        setFormInProgress(false);
        return;
      }

      if (saveUserDataToSessionStorage(user)){
        setLoginError('User validated!');
        setContextData({ isAuthed: true });
        loginSuccess();
      }
      return;
    }

    // Check issue
    switch (user.challengeName) {
      case 'NEW_PASSWORD_REQUIRED':
        setLoginError('Redirecting...');
        setContextData({ userAuthData: user });
        redirectTo('/signup');
        return;
    }

    setLoginError('Incorrect username or password');
    setFormInProgress(false);
  }

  function handleKeyUpEnter(e) {
    if (e.key === 'Enter' || e.keycode === 13) {
      handleSignIn();
    }
  }

  return (
    <div id="login" className="login">
      <div className="header">
        <a href="/"><img
          id="header-logo"
          src="./images/logos/alchemai-edge_desktop-logo.png"
          className="logo"
          alt="Alchemai EDGE"
        /></a>
      </div>
      <div className="content">
        <div className="banner">
          <div className="banner-internal">
            <div className="logo">
              <img 
                id="logo-right"
                src="./images/logos/alchemai-logo-white.png"
                alt="Alchemai Logo"
              />
            </div>
            <div className="title">Access Alchemai EDGE<br/>License Control Manager</div>
            <div className="subtitle">Complete your login information here!</div>
          </div>
        </div>
        <div className="form">
          <div id="login-form" className="login-form">
            <div className="title">Sign In</div>

            <div className="inputs-fields">
              <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="email">Email Adress</InputLabel>
                <OutlinedInput
                  id="email"
                  label="Email Address"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  disabled={formInProgress}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton disabled color="primary" edge="end">
                        <EmailOutlined />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  id="password"
                  label="Password"
                  type={passVisibility ? 'text' : 'password'}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  onKeyUp={(e) => handleKeyUpEnter(e)}
                  readOnly={formInProgress}
                  fullWidth 
                  inputProps={{ maxLength: 50 }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {passVisibility ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>

            <div className="login-error">
              {loginError}
            </div>

            <div className="remember">
              <Checkbox
                id="checkbox-remember"
                checked={remember}
                value="on"
                disabled={formInProgress}
                className="checkbox-remember"
                onChange={(e) => setRemember(e.target.checked)}
              />
              <span className="remember__text" onClick={() => setRemember(!remember)}>Remember me</span>
            </div>

            <div className="submit__row">
              <div className="submit__forgot">
                <span className="submit__forgot--text">
                  <a onClick={() => redirectTo('/forgotPassword')}>Forgot password?</a>
                </span>
              </div>

              <div className="submit__btn">
                <Button
                  id="sign-in"
                  variant="contained"
                  className={`button-login ${(isDisabled) ? 'button-login-disabled' : ''}`}
                  disabled={isDisabled}
                  onClick={handleSignIn}
                >
                  SIGN IN
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage;