import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './DashboardPage.scss';

function createData(
  Type_of_License: string,
  Renewal: string,
  Contract_Length: string,
  Payment_Terms: string,
  Max_Number_of_Users: number
) {
  return { Type_of_License, Renewal, Contract_Length, Payment_Terms, Max_Number_of_Users };
}
const rows = [
  createData('Bailment License', "60 days", "16 days", "Monthly", 50),
  createData('Select License', "Annually", "22 days", "Monthly", 44),
  createData('Standard License', "Annually", "11 days", "Monthly", 92),
  createData('Premium License', "Annually", "15 days", "Monthly", 21),
  createData('Enterprise-wide License', "Annually", "20 days", "Monthly", 94),
];

function LicenseModel({ handleCloseModal }) {
  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    // setAge(event.target.value as string);
  }

  return (
        <div className="license-modal-content">
          <div className="license-content-top">
            <div className="license-content-top-row">
              <div className="lct-form-box">
                <label>Please enter Company name:</label>
                <input placeholder="Company Name" value="" />
              </div>
              <div className="lct-form-box">
                <label>Select a data feeds from the list:</label>
                <select name="" id="">
                  <option value="">Timing</option>
                  <option value="">Timing 1</option>
                  <option value="">Timing 2</option>
                  <option value="">Timing 3</option>
                </select>
              </div>
            </div>
          </div>
          <div className="license-content-middle">
            <label>Select a data feeds from the list:</label>
            <div className="license-content-table">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">

                <TableHead>
                  <TableRow className="table-heading">
                    {/* <TableCell>SELECT</TableCell> */}
                    <TableCell>Select</TableCell>
                    <TableCell>TYPE OF LICENSE</TableCell>
                    <TableCell >RENEWAL</TableCell>
                    <TableCell>CONTRACT LENGTH</TableCell>
                    <TableCell >PAYMENT TERMS</TableCell>
                    <TableCell >MAX.NUMBERS <br /> OF USERS</TableCell>
                    <TableCell align="center" >EDIT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className="table-days">
                        <FormControlLabel value="select" control={<Radio />} label="" />
                      </TableCell>
                      <TableCell className="table-days"><b>{row.Type_of_License}</b></TableCell>
                      <TableCell className="table-days">{row.Renewal}</TableCell>
                      <TableCell className="table-days">{row.Contract_Length}</TableCell>
                      <TableCell className="table-days">{row.Payment_Terms}</TableCell>
                      <TableCell className="table-days">{row.Max_Number_of_Users}</TableCell>
                      <TableCell className="logo-table"> <EditIcon /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
          <div className="license-content-bottom">
            <Button autoFocus onClick={handleCloseModal}>
              add new client
            </Button>
          </div>
        </div>
  )
}

export default LicenseModel
