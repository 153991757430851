//import { useIsMounted } from '../helpers/global-helpers';

/**************************************
** Component styles
***************************************/
import './Layout.scss';
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HeaderDashboard from './header';
import { useHistory, useLocation } from 'react-router';

import logo from '../assets/images/logos/logo.png';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';

function menuItem(
    label: string,
    link: string,
    icon: any,
) {
    return { label, link, icon };
}

const drawerWidth = 240;

export function Layout({ children }): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const sectionListData = [
    menuItem("Organizations", "/organizations", <BusinessOutlinedIcon />),
    menuItem("Licenses", "/licenses", <GppGoodOutlinedIcon/>),
    menuItem("Templates", "/templates", <DescriptionOutlinedIcon/>),
    menuItem("Users", "/users", <PeopleOutlineOutlinedIcon/>)
  ];

  const checkSelectedListItem = (link: string) => {
    return (location.pathname.toLowerCase() === link.toLowerCase());
  }

  const clickListItem = (link: string) => {
    if (!link) return;

    history.push(link);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <HeaderDashboard />

      <Drawer
        className="sidebarSection"
        variant="permanent"
        sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <div>
          <img className="headerLogo" src={logo} />
        </div>
        <Box className="sidebarSectionInner" sx={{ overflow: 'auto' }}>
          <List>
            {sectionListData.map((item) => (
              <ListItem
                button
                key={item.link}
                className={`menuItem ${(checkSelectedListItem(item.link)) ? 'menuItemSelected' : ''}`}
                selected={checkSelectedListItem(item.link)}
                onClick={(e) => clickListItem(item.link)}
              >
                <ListItemIcon className="menuIcon">
                  {item.icon}
                </ListItemIcon>

                <ListItemText className={`menuText ${(checkSelectedListItem(item.link)) ? 'menuTextSelected' : ''}`} primary={item.label} />
              </ListItem>
            ))}
          </List>
          <div className="help_button">Help</div>
          <Divider />
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {children}
      </Box>
    </Box>
  );
}

export default Layout;