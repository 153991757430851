import React from 'react';
import ReactDOM from 'react-dom';
import { LicenseManager } from '@ag-grid-enterprise/core';
import AppContextProvider from './context/AppContextProvider';
import App from './App';

// AG GRID ENTERPRISE LICENSE KEY //
LicenseManager.setLicenseKey(
  'CompanyName=Alchemai,LicensedApplication=Alchemai EDGE,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-017791,ExpiryDate=10_September_2022_[v2]_MTY2Mjc2NDQwMDAwMA==c713c68f03f6b5907c0c48f971eeaeb9'
);

ReactDOM.render(
  <AppContextProvider>
    <App />
  </AppContextProvider>,
  document.getElementById('root')
);
