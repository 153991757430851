import React, { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { Toaster } from 'react-hot-toast';
import { AppContext } from './context/AppContext';

// Pages and internal components //
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import SignUpPage from './pages/SignUpPage';
import OrganizationPage from './pages/organization/OrganizationPage';
import UsersPage from './pages/users/UsersPage';
import LogoutPage from './pages/LogoutPage';

// Theme settings
import { createTheme, ThemeProvider } from '@mui/material';
const theme = createTheme({
  typography: {
      fontFamily: "'Work Sans', Arial, Helvetica, sans-serif"
  },
});

// Helpers //
import {
  useIsMounted,
  saveUserDataToSessionStorage,
  clearUserDataToSessionStorage,
} from './helpers/global-helpers';
import LicensePage from './pages/LicensePage';
import TemplatesPage from './pages/TemplatesPage';
import UserAccessPage from './pages/UserAccessPage';

import './App.scss';

// Set up Amplify data
const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const userPoolWebClientId = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;
const domain = process.env.REACT_APP_COGNITO_DOMAIN;

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: userPoolId,
    userPoolWebClientId: userPoolWebClientId,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: domain,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'http://localhost:8080/api/login',
      redirectSignOut: 'http://localhost:8080/api/logout',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
});

export const App: React.FC = () => {
  const isMounted = useIsMounted();

  // Read Context Data values
  const { contextData, setContextData } = useContext(AppContext);
  const { isAuthed } = contextData;

  const [isRequestingAuth, setIsRequestingAuth] = useState(true);

  const checkIsLoggedIn = async () => {
    await Auth.currentAuthenticatedUser()
      .then(user => {
        saveUserDataToSessionStorage(user);
        setContextData({ isAuthed: true });
        setIsRequestingAuth(false);
      })
      .catch(err => {
        clearUserDataToSessionStorage();
        setIsRequestingAuth(false);
      });
  }

  useEffect(() => {
    if (!isMounted.current) return;

    checkIsLoggedIn();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Toaster />

        {!isRequestingAuth && isAuthed && (
          <Switch>
            <Route path="/organizations" exact component={OrganizationPage} />
            <Route path="/licenses" exact component={LicensePage} />
            <Route path="/templates" exact component={TemplatesPage} />
            <Route path="/userAccess" exact component={UserAccessPage} />
            <Route path="/users" exact component={UsersPage} />
            <Route path="/logout" exact component={LogoutPage} />
            <Redirect from="*" to="/organizations" />
          </Switch>
        )}

        {!isRequestingAuth && !isAuthed && (
          <Switch>
            <Route path="/login" exact component={LoginPage} />
            <Route path="/login/:id" exact component={LoginPage} />
            <Route path="/forgotPassword" exact component={ForgotPasswordPage} />
            <Route path="/forgotPassword/:id" exact component={ForgotPasswordPage} />
            <Route path="/signup" exact component={SignUpPage} />
            <Redirect from="*" to='/login' />
          </Switch>
        )}

      </Router>
    </ThemeProvider>
  );
};

export default App;
