import React, { useEffect, useState } from 'react';
import {
  FormControl,  OutlinedInput, Select, MenuItem, ListItemText,
  Button, InputAdornment, Grid, Tooltip, Checkbox,
} from '@mui/material';
import {
  useIsMounted, showToastMessage, hideToastMessage, 
} from '../../helpers/global-helpers';
import { editUser } from '../../api/LCMApi';
import './UsersViewPage.scss';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface UsersViewPageInterface {
  userData: {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  },
  onClose: any;
}

const UsersViewPage = (props: UsersViewPageInterface) => {
  const isMounted = useIsMounted();

  const [firstName, setFirstName] = useState(props.userData.firstName);
  const [lastName, setLastName] = useState(props.userData.lastName);
  const [email, setEmail] = useState(props.userData.email);
  const [phoneNumber, setPhoneNumber] = useState(props.userData.phoneNumber);

  const closeAction = () => {
    props.onClose();
  }

  useEffect(() => {
    if (!isMounted.current) return;
    if (!props.userData) return;

    setFirstName(props.userData.firstName);
    setLastName(props.userData.lastName);
    setEmail(props.userData.email);
    setPhoneNumber(props.userData.phoneNumber);
  }, [props.userData]);

  return (
    <div className="main-form">
      <div className="form-title">User Information</div>
      <div className="form-body">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="form-label">Name</div>
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
              <OutlinedInput
                id="firstName"
                value={firstName}
                readOnly={true}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <div className="form-label">Lastname</div>
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
              <OutlinedInput
                id="lastName"
                value={lastName}
                readOnly={true}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div className="form-label">Email</div>
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
              <OutlinedInput
                id="email"
                value={email}
                readOnly={true}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div className="form-label">Phone Number</div>
            <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
              <OutlinedInput
                id="phoneNumber"
                value={phoneNumber}
                readOnly={true}
                fullWidth
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <div className="form-actions">
              <Button
                id="edit-user-confirm"
                className="button"
                variant="contained"
                style={{
                  backgroundColor: "#077764",
                  color: "#ffffff",
                }}
                onClick={() => closeAction()}
              >
                CLOSE
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default UsersViewPage;
